.photographers-preview {
  padding: clamp(2rem, 5vw, 4rem) clamp(1rem, 2vw, 2rem);
  background: #2d2d2d;
  text-align: center;
}

.preview-title {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  font-weight: bold;
  color: #f39c12;
  margin-bottom: clamp(1rem, 2vw, 2rem);
}

.preview-card {
  position: relative;
  height: 400px;
  overflow: hidden;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s ease;
  display: block;
  min-height: 400px;
}

.preview-card:hover .preview-image {
  filter: brightness(60%);
}

.preview-details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.7);
  color: white;
}

.preview-name {
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-weight: bold;
  margin: 0;
}

.preview-desc {
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  margin: 0.5rem 0 0;
}

.swiper-button-prev-preview,
.swiper-button-next-preview {
  width: clamp(30px, 4vw, 40px);
  height: clamp(30px, 4vw, 40px);
  background: #fff;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.swiper-button-prev-preview {
  left: clamp(5px, 1vw, 10px);
}

.swiper-button-next-preview {
  right: clamp(5px, 1vw, 10px);
}

.swiper-button-prev-preview svg,
.swiper-button-next-preview svg {
  font-size: clamp(14px, 2vw, 20px);
  color: #000;
}

.swiper-button-prev-preview:hover,
.swiper-button-next-preview:hover {
  background-color: #f0f0f0;
}

.explore-button {
  display: inline-block;
  margin-top: clamp(1rem, 2vw, 2rem);
  padding: clamp(8px, 1.5vw, 12px) clamp(15px, 2vw, 25px);
  background: #f39c12;
  color: #fff;
  text-decoration: none;
  font-size: clamp(0.9rem, 1.5vw, 1.2rem);
  font-weight: bold;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.explore-button:hover {
  background: #e08c00;
}

@media (min-width: 1024px) {

  .swiper-button-prev-preview,
  .swiper-button-next-preview {
    display: none;
  }
}

@media (max-width: 768px) {
  .preview-card {
    height: 300px;
  }

  .preview-image {
    min-height: 300px;
  }
}

@media (max-width: 480px) {
  .preview-card {
    height: 250px;
  }

  .preview-image {
    min-height: 250px;
  }
}