.news-container {
  width: 80%;
  height: 320px;
  border-radius: 1rem;
  margin: 1rem;
  box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  backdrop-filter: blur(2px);
}
.spacing {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spacing-title {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 700;
}
.news-description {
  height: 90%;
  border-left: 1px solid #ffffff;
  padding-left: 1rem;
}

@media screen and (max-width: 1440px) {
  .news-container {
    height: 250px;
  }
  .spacing {
    width: 60%;
  }
  .spacing-title {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 1024px) {
  .news-container {
    height: 230px;
    width: 90%;
  }
  .spacing-title {
    margin: 1rem;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 568px) {
}
@media screen and (max-width: 426px) {
}
@media screen and (max-width: 361px) {
}
