.LabelBook {
  min-height: 400px;
  border-radius: 15px;
  padding: 0 2rem;
  margin-top: 2rem;
  display: flex;
  width: 100%;
  box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 4rem;
}

.label-section {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.label-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 120px;
  &:hover {
    cursor: pointer;
  }
}
.label-item-img {
  border-radius: 10px;
  // background: #e0e0e0;
  width: 200px;
  height: 100px;
  box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.25);
}

.artwork-preview-section {
  width: 50%;
}

.artwork-preview-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  height: 100%;
}

.artist-list-section {
  margin: 1rem 0;
}

.label-name {
  font-weight: 600;
  font-size: 1.3rem;
  text-transform: uppercase;
}

.underline {
  width: 100%;
  border-bottom: 2px solid #e0e0e0;
}

.preview-main-img {
  border-radius: 10px;
  display: flex;
  background: #e0e0e0;
  height: 70%;
  width: 90%;
  box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

.artwork-featured-link {
  margin-top: 1rem;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 90%;
  background: #ff5d00;
  color: #fff;
}

.preview-context {
  width: 90%;
  margin-top: 1.2rem;
}

.preview-title {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
  .artwork-preview-content {
    width: 100%;
  }
  .preview-main-img {
    margin-top: 2rem;
    height: 300px;
  }
  .LabelBook {
    flex-direction: column;
  }
  .label-section {
    width: 100%;
  }
  .artwork-preview-section {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .artwork-featured-link {
    border-radius: 5px;
    width: 100%;
  }
}
@media screen and (max-width: 568px) {
}
@media screen and (max-width: 426px) {
}
@media screen and (max-width: 361px) {
}
