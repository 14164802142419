.section-title {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 90%;
  margin: 50px auto;
}

.section-title {
  h2 {
    font-size: 1.3rem;
    font-weight: bold;
    white-space: nowrap;
  }
  hr {
    flex-grow: 1;
    border: none;
    border-top: 1px solid #000;
  }
}
