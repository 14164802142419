.artworks-carousel {
  padding: 20px;
  width: 90%;
  margin: auto;
  .carousel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      font-size: 26px;
      font-weight: bold;
    }

    span {
      color: gray;
    }

    .view-all-btn {
      background: none;
      border: 1px solid black;
      padding: 5px 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);

      &:hover {
        background-color: rgb(222, 222, 222);
        box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .slick-track {
    display: flex;
    margin-right: 0;
    gap: 0.5%;
    margin-left: 0;
  }

  .slick-slide {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .artwork-card {
    position: relative;
    overflow: hidden;
    flex: 1 1 auto;
    height: 250px;

    img {
      width: 100% !important;
      height: 100%;
      border: 1px solid #ccc;
    }

    &:hover {
      .artwork-info {
        opacity: 1;
      }
    }

    .artwork-info {
      position: absolute;
      bottom: -1%;
      left: 0%;
      right: 0%;
      background: rgba(255, 255, 255, 0.9);
      width: 101% !important;
      opacity: 0;
      transition: opacity 0.3s ease;

      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
      }

      p {
        margin: 0;
        font-size: 14px;
        color: gray;
      }

      .learn-btn {
        margin-top: 5px;
        background: none;
        border: 1px solid black;
        padding: 5px 10px;
        cursor: pointer;
        color: #000;
        transition: all 0.3s ease;
        list-style: none;
        height: 10%;
        &:hover {
          background-color: rgb(222, 222, 222);
        }
      }

      .art-info-2 {
        display: flex;
        padding: 4% !important;
        justify-content: space-between;
      }
    }
  }

  .scroll-line {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    position: relative;
    height: 4px;
    background-color: #e0e0e0;
    width: 98%;

    .scroll-section {
      flex-grow: 1;
      height: 200%;
      background-color: transparent;
      transition: background-color 0.3s ease-in-out;

      &.active {
        background-color: #000;
      }

      &:not(:last-child) {
        margin-right: 2px;
      }
    }
  }
  .scroll-section.full {
    flex-grow: 1;
    background-color: #000;
  }

  @media (max-width: 992px) {
    .slick-slide {
      width: 50% !important;
    }
  }

  @media (max-width: 768px) {
    .slick-slide {
      width: 100% !important;
    }
    .slick-track {
      gap: 0;
    }
  }
}

.custom-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.next-arrow {
  right: 0px;
}

.prev-arrow {
  left: 10px;
}

.custom-arrow span {
  font-size: 20px;
  color: black;
}

.custom-arrow:hover {
  background-color: #f0f0f0;
}
