.box-card {
  border: 1px solid #ddd;
  padding: 14% 10% 10%;
  text-align: center;
  transition: all 0.3s ease-in-out;
  margin: 0 auto 5% auto;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 380px;

  &:hover {
    background: #f9f9f9;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
  }

  &.expanded {
    background-color: #f9f9f9;
  }

  .box-icon {
    width: 30%;
    height: auto;
    margin-bottom: 9%;
  }

  .box-title {
    font-size: 1.2rem;
    margin: 8px 0;
    text-align: left;
  }

  .box-content {
    font-size: 1rem;
    margin: 12px 0;
    text-align: left;
  }

  .box-toggle {
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .box-toggle:hover {
    color: #000;
  }

  .arrow-icon {
    font-size: 35px;
    color: #000;
    transition: transform 0.6s ease-in-out;

    &:hover {
      color: #000;
    }

    &.rotated {
      transform: rotate(180deg);
    }
  }

  /* Responsive Design */
  @media (max-width: 1024px) {
    padding: 16% 8% 8%;

    .box-title {
      font-size: 1.1rem;
    }

    .box-content {
      font-size: 0.9rem;
    }

    .arrow-icon {
      font-size: 30px;
    }
  }
  .a-img-item {
    -webkit-background-size: cover;
    background-size: cover;
    -webkit-background-position: center;
    background-position: center;
    -webkit-background-repeat: no-repeat;
    background-repeat: no-repeat;

    transition: all 0.3s ease-in-out;

    width: 150px;
    height: 150px;
    margin: auto;
    position: relative;
  }

  @media (max-width: 900px) {
    height: 300px;
  }
  @media (max-width: 768px) {
    padding: 15% 5% 5%;

    .a-img-item {
      padding-top: 0;
      width: 110px;
      height: 110px;
    }
    .box-title {
      font-size: 1rem;
      text-align: center;
    }

    .box-content {
      font-size: 0.85rem;
      text-align: center;
    }

    .arrow-icon {
      font-size: 28px;
    }
  }

  @media (max-width: 480px) {
    padding: 10% 5% 5%;

    .box-title {
      font-size: 0.9rem;
      text-align: center;
    }

    .box-content {
      font-size: 0.8rem;
      text-align: center;
    }

    .arrow-icon {
      font-size: 25px;
    }
  }
}
