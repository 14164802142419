.container {
  display: flex;
  height: 58vh;
  margin-bottom: 3%;
}
.paragraph {
  border-bottom: 3px #000 solid;
  padding-bottom: 12px;
}
.image-section {
  flex: 1;
}
.sub_content {
  width: 85%;
}
.art-image,
.art-image2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date {
  font-size: 12px;
}
.content_two {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
}
.full-article-btn {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
}

.full-article-btn:hover {
  background-color: #444;
}

.box-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1%;
  width: 85%;
  margin: 5% auto;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
    width: 87%;
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
  }

  @media (max-width: 580px) {
    padding: 8px;
    grid-template-columns: repeat(1, 1fr);
  }
}
.articles-box-card {
  max-width: 350px;
}
@media (max-width: 1024px) {
  .image-section {
    height: 50vh !important;
  }
  .container {
    flex-direction: column;
    height: auto;
  }
  .sub_content {
    width: 85%;
  }
  .art-image {
    height: 50vh;
  }
  .content-section {
    height: 50vh;
    margin: 4% 0;
  }
}
.second_section {
  width: 85%;
  margin: 3% auto;
}

.second_section_content,
.sub_content {
  h2 {
    font-size: 17px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 15px;
    margin-bottom: 10px;
    color: black;
  }

  p {
    font-size: 13px;
    line-height: 1.6;
  }
}

.second_section_content {
  .content-item {
    padding: 2% 0;
  }
  .image-section {
    height: 65vh;
    margin: 3% 0;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.4);
  }
  .second_p {
    margin-bottom: 3%;
  }
}
.second_content {
  padding-top: 3%;
  border-top: 1px #000 solid;
}

.notification-container {
  background-color: black;
  color: white;
  text-align: center;
  padding: 4px 10px;
  font-size: 14px;
  font-family: Arial, sans-serif;
}

.notification-text {
  margin: 0;
}

.notification-link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.notification-link:hover {
  color: #e0e0e0;
}
@media (max-width: 600px) {
  .image-section {
    height: 25vh !important;
  }
}
