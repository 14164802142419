.purchases-container {
  width: 100%;
  height: 4rem;
  background-color: green;
}

.purchases-inputs {
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.container-input {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.history-order-id {
  @extend .container-input;
  width: 10%;
  background-color: #5a5a5a;
  color: #fff;
}
.history-order-title {
  @extend .container-input;
  width: 65%;
  background-color: #fff;
}
.history-order-date {
  @extend .container-input;
  width: 10%;
  color: #fff;
  background-color: #5a5a5a;
}

.history-order-type {
  @extend .container-input;
  width: 12%;
  background-color: #fff;
}
.history-order-detail {
  @extend .container-input;
  width: 3%;
  color: #fff;
  background-color: #5a5a5a;
}

.history-order-img {
  height: 100%;
  width: 10%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
  /* Должно быть выше остальных элементов */
}
