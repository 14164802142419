.certificate-tool {
  display: flex;
  gap: 100px;
  width: 85%;
  margin: 1rem auto;
  min-height: 100vh;
  align-items: center;

  .upload-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    .price-section {
      display: flex;
      align-items: center;
      gap: 10px;

      .group {
        display: flex;
      }
      span {
        flex: 1;
        font-size: 14px;
        color: #000;
      }

      input {
        padding: 10px;
        border: 1px solid #000;
        border-radius: 5px 0 0 5px;
        width: 30px;
      }

      select {
        padding: 10px;
        border: 1px solid #000;
        border-radius: 0 5px 5px 0;
      }
    }

    .upload-box {
      border: 2px solid #000000;
      border-radius: 10px;
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      cursor: pointer;
      margin-bottom: 30px;

      span {
        font-size: 12px;
        color: #666;
      }

      .uploaded-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .upload-input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  .form-section {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .label-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .form-label {
        position: relative;
        display: inline;
        margin: 0;
      }
      input {
        display: inline;
      }
      textarea {
        display: block;
      }
      input {
        padding: 10px;
        border: 1px solid #000000;
        border-radius: 5px;
        font-size: 14px;
        width: 30%;
      }

      textarea {
        height: 100px;
        resize: none;
      }

      .icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        cursor: pointer;
      }

      .categories-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 5px;

        .category-item {
          background: #b4b4b4;
          border: 2px solid #747474;
          padding: 5px 10px;
          border-radius: 15px;
          display: flex;
          align-items: center;
          gap: 5px;

          .remove-category {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 12px;
            font-weight: 600;
            color: #ff0000;
          }
        }

        .category-input {
          display: flex;
          gap: 10px;
          align-items: center;

          input {
            flex: 1;
          }

          button {
            background: #000;
            color: #fff;
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }

    .type-selection {
      display: flex;
      gap: 10px;
      margin-top: 10px;
      flex-wrap: wrap;

      button {
        padding: 10px 20px;
        border: 1px solid #000;
        border-radius: 5px;
        background: none;
        cursor: pointer;

        &.selected {
          background: #000;
          color: #fff;
        }
      }
    }

    .verify-button {
      background: #000;
      color: #fff;
      padding: 15px;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}
.textarea {
  padding: 10px;
  border: 2px solid #000000;
  border-radius: 10px;
  font-size: 14px;
  margin-top: 5px;
}

.textarea {
  height: 100px;
  resize: none;
}

.container2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
  font-family: Arial, sans-serif;
}

.editable-wrapper {
  display: flex;
  align-items: center;
}

.editable-text {
  cursor: pointer;
  display: flex;
}

.edit-icon {
  margin-left: 5px;
  font-size: 14px;
}

.editable-input {
  border: none;
  outline: none;
  background: transparent;
  font-size: inherit;
  font-family: inherit;
  width: auto;
}

@media (max-width: 1024px) {
  .certificate-tool {
    gap: 30px;
    margin: 0 auto;
  }

  .upload-section .upload-box {
    height: 250px;
  }
}

@media (max-width: 768px) {
  .certificate-tool {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 5rem auto;
  }

  .upload-section,
  .form-section {
    flex: none;
    width: 100%;
  }

  .upload-section .upload-box {
    height: 200px;
  }

  .price-section {
    flex-direction: column;
    align-items: stretch;
  }
}

@media (max-width: 480px) {
  .certificate-tool {
    padding: 10px;
    width: 93%;
    display: flex;
    justify-content: center;
  }

  .upload-section .upload-box {
    height: 150px;
  }

  .form-section .type-selection button {
    padding: 8px 15px;
    font-size: 12px;
  }

  .form-section .verify-button {
    padding: 10px;
    font-size: 14px;
  }

  .price-section input,
  .price-section select {
    padding: 8px;
  }
}
.artist-profile {
  display: flex;
  gap: 100px;
  width: 85%;
  margin: 1rem auto;
  min-height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;

  .upload-section {
    flex: 1;
    display: flex;
    flex-direction: column;

    .upload-box {
      border: 2px solid #000000;
      border-radius: 10px;
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      cursor: pointer;
      margin-bottom: 30px;

      span {
        font-size: 12px;
        color: #666;
      }

      .uploaded-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .upload-input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  .form-section {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .label-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .form-label {
        position: relative;
        display: inline;
        margin: 0;
      }

      input {
        padding: 10px;
        border: 1px solid #000000;
        border-radius: 5px;
        font-size: 14px;
      }

      .editable-textarea {
        padding: 10px;
        border: 2px solid #000000;
        border-radius: 10px;
        font-size: 14px;
        height: 100px;
        resize: none;
        background: transparent;
        outline: none;
        width: 100%;
      }

      .trajectories-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .trajectory-item {
          background: #b4b4b4;
          border: 2px solid #747474;
          padding: 5px 10px;
          border-radius: 15px;
          display: flex;
          align-items: center;
          gap: 5px;

          .remove-trajectory {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 12px;
            font-weight: 600;
            color: #ff0000;
          }
        }

        .trajectory-input {
          display: flex;
          gap: 10px;
          align-items: center;

          input {
            flex: 1;
          }

          button {
            background: #000;
            color: #fff;
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }

    .type-selection {
      display: flex;
      gap: 10px;
      margin-top: 10px;
      flex-wrap: wrap;

      button {
        padding: 10px 20px;
        border: 1px solid #000;
        border-radius: 5px;
        background: none;
        cursor: pointer;

        &.selected {
          background: #000;
          color: #fff;
        }
      }
    }

    .verify-button {
      background: #000;
      color: #fff;
      padding: 15px;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: bold;
    }

    .error-message {
      color: #ff0000;
      margin-top: 10px;
      font-size: 14px;
    }

    .success-message {
      color: #00cc00;
      margin-top: 10px;
      font-size: 14px;
      padding: 10px;
      background: rgba(0, 204, 0, 0.1);
      border-radius: 5px;
    }

    .editable-wrapper {
      display: flex;
      align-items: center;
    }

    .editable-text {
      cursor: pointer;
      display: flex;
    }

    .edit-icon {
      margin-left: 5px;
      font-size: 14px;
    }

    .editable-input {
      border: none;
      outline: none;
      background: transparent;
      font-size: inherit;
      font-family: inherit;
      width: auto;
    }
  }
}

@media (max-width: 1024px) {
  .artist-profile {
    gap: 30px;
    margin: 0 auto;
  }

  .upload-section .upload-box {
    height: 250px;
  }
}

@media (max-width: 768px) {
  .artist-profile {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 5rem auto;
  }

  .upload-section,
  .form-section {
    flex: none;
    width: 100%;
  }

  .upload-section .upload-box {
    height: 200px;
  }
}

@media (max-width: 480px) {
  .artist-profile {
    padding: 10px;
    width: 93%;
  }

  .upload-section .upload-box {
    height: 150px;
  }

  .form-section .type-selection button {
    padding: 8px 15px;
    font-size: 12px;
  }

  .form-section .verify-button {
    padding: 10px;
    font-size: 14px;
  }
}
@media (max-height: 600px) {
  .artist-profile,
  .certificate-tool {
    margin-top: 4rem !important;
  }
}

.rarity-selection {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;

  .rarity-dropdown {
    padding: 9px;
    font-size: 16px;
    border-radius: 5px;
    width: 30%;
  }
}
