.request-card {
    display: flex;
    box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    
    .request-image {
      width: 200px;
      min-width: 200px;
      height: 200px;
      overflow: hidden;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    .request-body {
      flex: 1;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  
    .request-text {
      .art-name {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 5px;
      }
  
      .artist-name {
        font-size: 1.1rem;
        color: #666;
        margin-bottom: 10px;
      }
  
      .request-details {
        margin-bottom: 15px;
        
        .detail-item {
          margin: 5px 0;
          
          .label {
            font-weight: bold;
            margin-right: 5px;
          }
          
          &.price {
            font-weight: bold;
            color: #2ecc71;
          }
        }
      }
  
      .description {
        .label {
          font-weight: bold;
        }
        
        p {
          margin: 5px 0 0;
          color: #444;
          line-height: 1.4;
        }
      }
    }
  
    .requests-action {
      margin-top: 15px;
      
      .request-delete {
        padding: 8px 15px;
        background-color: #e74c3c;
        color: white;
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;
        
        &:hover {
          background-color: #c0392b;
        }
      }
    }
  }