.contact-form {
  margin: 3rem 0;
  display: flex;
  width: 70%;
  justify-content: space-between;
}
.forms {
  width: 40%;
  border-radius: 15px;
  box-shadow: 5px 6px 10px 1px rgba(0, 0, 0, 0.25);
  max-width: 600px;
  form {
    margin: 1rem 2rem;
  }
}

.contact-form-title {
  flex-direction: column;
  color: #000000;
  font-size: 2rem;
  text-transform: uppercase;
  width: 50%;
  display: flex;

  justify-content: center;
  p {
    margin-top: 1rem;
    text-transform: capitalize;
    width: 80%;
    color: #000;
    font-size: 1rem;
  }
}

.contact-form p {
  margin-bottom: 20px;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  font-size: 1rem;
  padding: 0.4rem;
  box-sizing: border-box;
}

.contact-form button {
  border-radius: 10px;
  background-color: #000000;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;

  border: none;
  cursor: pointer;
  p {
    margin: 0.5rem 1rem;
  }
}
.contact-input {
  margin-bottom: 1rem;
}

@media screen and (max-width: 1440px) {
  .forms {
    width: 70%;
  }
  .contact-form {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-form-title {
    font-size: 1.5rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .contact-form-title {
    width: 95%;
  }
  .forms {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .contact-form {
    margin-top: 2rem;
  }
  .contact-input {
    font-size: 0.9rem;
  }
  .contact-form button {
    font-size: 1rem;
  }
  .contact-form-title {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-size: 0.8rem;
    p {
    }
  }
}
