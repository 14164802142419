.SinglePageField {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.spf-container {
  width: 80%;
  box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spf-title {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  // padding-bottom: 1rem;
  font-size: 1.5rem;
  width: 95%;
  border-bottom: 1px solid #000;
}
.mpf-title {
  cursor: pointer;
  margin-top: 2rem;
  display: flex;
  font-size: 1.5rem;
  width: 95%;
  border-bottom: 1px solid #000;
}

.spf-main-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  padding-bottom: 0.2rem;
  border-bottom: 3px solid #000;
}
.mpf-main-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem;
  padding-bottom: 0.2rem;
  &.active {
    border-bottom: 3px solid #000;
  }
}

.spf-extra-field {
  display: flex;
  font-size: 1.2rem;
  color: #5e5e5e;
  align-items: center;

  div {
    display: flex;
  }
}

.spf-content {
  width: 95%;
  margin: 1rem 0;
}

@media screen and (max-width: 768px) {
  .spf-title {
    margin-top: 1rem;
  }
  .spf-main-title {
    font-size: 0.9rem;
  }
  .spf-content {
    margin-top: 0.8rem;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 600px) {
  .spf-container {
    width: 92%;
  }
  /* Additional styles for small screens */
}

/* Media query for screens from 601 to 1024 pixels wide (e.g., tablets) */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  /* Additional styles for medium screens */
}

/* Media query for screens 1025 pixels wide and above (e.g., desktops) */
@media screen and (min-width: 1025px) {
  /* Additional styles for large screens */
}

/* Example media query for touch input devices (e.g., tablets and smartphones) */
@media screen and (hover: none) {
  /* Additional styles for touch input devices */
}
