.selected-field {
  width: 100%;
}
.collections {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  justify-items: center;
  margin-bottom: 1rem;
}

.collection-counter {
  color: #000;
}
.collection-link {
  width: 90%;
}
.collection-item {
  align-items: center;
  width: 100%;
  display: flex;
}

.clct-btn {
  p {
    margin: 0.3rem;
  }
  justify-content: center;
  display: flex;
  text-transform: uppercase;
  color: #fff;
  background: #ff5e00;
  cursor: pointer;
}

.bullet-grid {
  width: 10px;
  height: 10px;
  background: #3d3d3d;
  border-radius: 50%;
}
.collection_preview {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #3d3d3d;
  margin: 1rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.collection-name {
  font-size: 1.3rem;
  color: #ff5e00;
}

.collection-item {
  margin-bottom: 1rem;
  border-bottom: #3d3d3d 1px solid;
}

@media screen and (max-width: 768px) {
  .collections {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
  .collection-item {
    margin-bottom: 0.7rem;
  }
  .collection-name {
    font-size: 0.9rem;
  }
  .collection-counter {
    font-size: 0.8rem;
  }
  .collection_preview {
    margin: 0.6rem;
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 568px) {
  .clct-btn {
    p {
      font-size: 0.8rem;
    }
  }
}
