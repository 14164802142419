.fashion-tool-container {
  position: relative;
  background: #f4f4f4;
  height: calc(100vh - 5rem);
  overflow: hidden;

  .canvas-container {
    overflow: hidden;
    height: 100%;
    flex-grow: 1;
  }

  .sprint-troke {
    display: flex;
  }

  .print-label {
    width: 30%;
  }

  .print-details {
    margin-bottom: 1rem;
  }

  .settings-panel {
    position: fixed;
    right: 0;
    top: 55%;
    transform: translateX(0) translateY(-50%);
    padding: 20px;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
    height: 65%;
    transition: transform 0.3s ease-in-out;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 25px 0 0 25px;
    width: calc(397px - 40px);
    max-height: 80vh;
    overflow-y: auto;

    label {
      font-weight: bold;
      margin-bottom: 0.6rem;
      display: block;
      color: #333;
    }

    .input-group {
      margin-bottom: 15px;

      input[type="color"] {
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        display: inline;
        margin-right: 10px;
      }

      input[type="range"] {
        width: 100%;
      }

      span {
        color: #000;
      }
    }

    .Pattern,
    .Alpha,
    .active,
    .Back,
    .Front {
      border-left: 0;
    }

    .mode-buttons {
      display: flex;
      width: 50%;

      button {
        border: 2px solid #000;
        flex: 1;
        padding: 4px;
        background: #fff;
        cursor: pointer;
        transition: 0.3s;

        &.active {
          background: #000;
          color: #fff;
        }

        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }

    select {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-bottom: 15px;
    }
  }
}

.settings-panel.closed {
  transform: translateX(100%) translateY(-50%);
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 5px;
  border: 1px solid #ccc;
}

.settings-panel::-webkit-scrollbar {
  width: 8px;
}

.settings-panel::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.settings-panel::-webkit-scrollbar-thumb {
  background-color: #afafaf;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.settings-panel::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.settings-panel::-webkit-scrollbar-button {
  display: none;
}

.settings-panel {
  &.closed {
    transform: translate(100%, -50%);
  }

  &.open {
    .toggle-button {
      transform: translate(30%, 50%);
      transition: transform 0.3s ease-in-out;
    }
  }
}

.toggle-b {
  height: 90px;
  border: 2px solid black;
  border-radius: 20px;
  background: white;
  width: 10px;
  height: 90px;
}

.toggle-button {
  position: fixed;
  top: 50%;
  transform: translate(0%, 0%);
  background: white;
  height: 105px;
  width: 22px;
  right: 0;
  border-radius: 8px 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 1000;

  // left: 97.5%;
  &.moved {
    transform: translate(-397px, 0%);
  }
}

.customer-form {
  font-family: Arial, sans-serif;

  .title {
    font-weight: bold;
    font-size: 1.1rem;
    margin-top: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .form-group {
    margin-bottom: 0.5rem;

    .label {
      display: block;
      font-size: 0.9rem;
      margin-bottom: 0.3rem;
    }

    .input {
      width: 100%;
      padding: 0.4rem;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
    }
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;

    .button {
      padding: 0.3rem;
      border: 1px solid #000;
      border-radius: 4px;
      background-color: #fff;
      cursor: pointer;

      &.active {
        background-color: #000;
        color: #fff;
      }

      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }

  .size-buttons {
    gap: 0.2rem;

    .button {
      padding: 0.3rem 0.6rem;
    }
  }

  .address-group {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.8rem;
  }
}

.color-display {
  display: flex;
  align-items: center;
  border: none;
}

.fashion-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  background: #000;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.fashion-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #000;
  cursor: pointer;
}

.fashion-slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #000;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background: black;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.margin {
  margin-bottom: 1rem;
}

.center {
  text-align: center;
  display: block;
}

.export-button-container {
  text-align: center;
  margin-top: 20px;
}

.export-button {
  background-color: #000000;
  width: 100%;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.import-button {
  margin-top: 1rem;
  background: #000;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: #303030;
  }
}

.export-button:hover {
  background-color: #333333;
}

.print-options {
  display: flex;
}

.print-view {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  cursor: pointer;
  position: relative;
  margin-right: 1rem;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #fff;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-radius: 5px;
    border: 1px solid #585858;
  }
}

.selected-option {
  width: 16px;
  height: 16px;
  position: absolute;
  background: #000000;
  bottom: 0;
  right: 0;
  border-radius: 20px 0 0 0;

  &:hover {
    border: 1px 0 0 0 solid #585858;
  }
}

@media (max-width: 1024px) {
  .toggle-button.moved {
    transform: translate(-310px, 0%);
  }

  .fashion-tool-container .settings-panel {
    width: 270px;
    height: 50%;
  }
}
