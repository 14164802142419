.art-single-page {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 1rem;
}

.ars-container {
  width: 80%;

  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.curency-converter {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  text-align: right;
  justify-content: right;
}

.artst-ava {
  margin-left: 1rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.ars-preview {
  height: 400px;
  box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;

  @media (min-width: 768px) {
    height: 500px;
    min-width: 40%;
    max-width: 100%;
    margin-bottom: 0;
  }
}
.loading-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #aaa;
}

.ars-preview-container {
  display: flex;
  flex-direction: column;

  justify-content: center;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 768px) {
    width: 50%;
  }
}

.ars-info {
  width: 100%;
  margin-top: 2rem;

  @media (min-width: 768px) {
    width: 40%;
    margin-top: 0;
  }
}

.ars-buffer {
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: 10%;
  }
}

.ars-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid #000;
}

.art-title-name {
  color: rgb(92, 91, 91);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 0.3rem;
}

.ars-title-under {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.ars-author {
  color: #ff9318;
}

.static-information {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #b3b3b3;
}

.ars-field {
  display: flex;
  margin-bottom: 0.8rem;
  width: 100%;
}

.ars-inf-field {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  width: 20%;
  font-weight: 600;
}

.ars-inf-subfield {
  width: 80%;
  ul {
    li {
      margin-right: 0.5rem;
    }
    display: flex;
  }
}

.description-container {
  display: flex;
  width: 100%;
}

.description-field {
  margin-top: 1rem;
}

.sector-content {
  color: #5e5e5e;
}

.purchase_btn {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  background: #505050;
  border-radius: 1rem;
  box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  p {
    text-transform: uppercase;
    margin: 1rem;
    color: #fff;
  }
}

.ars-full-bio {
  margin: 0 1rem;
}

.linker a {
  margin-bottom: 0.5rem;
  text-decoration: none;
  color: #505050;
  display: flex;
  align-items: center;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content img,
.modal-content video {
  max-height: 100%;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  margin-right: 1rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  padding: 0.5rem;

  &:hover {
    color: #ff9318;
  }
}

.stripe_link {
  text-transform: uppercase;
  margin: 1rem;
  color: #fff;
}

.share-btn {
  &:hover {
    cursor: pointer;
  }
}
@media (min-width: 4300px) {
  .art-single-page {
    margin-top: 12rem;
  }
}
@media (max-width: 1024px) {
  .art-single-page {
    margin-top: 8rem;
  }
}
@media screen and (max-width: 768px) {
  .ars-preview {
    margin-bottom: 1rem;
  }
  .ars-container {
    margin-bottom: 1rem;
  }
  .share-btn {
    font-size: 0.8rem;
  }
  .ars-rarity {
    font-size: 0.8rem;
  }
  .purchase_btn {
    border-radius: 10px;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
  .ars-title-under {
    margin-top: 0.5rem;
  }
  .ars-author {
    font-size: 0.9rem;
  }
  .artst-ava {
    margin-left: 0.5rem;
    width: 30px;
    height: 30px;
  }
  .linker {
    font-size: 0.8rem;
  }
  .stripe_link {
    font-size: 0.8rem;
  }
  .ars-title {
    padding-bottom: 0.2rem;
  }
  .art-title-name {
    font-size: 1rem;
  }
  .ars-full-bio {
    font-size: 0.8rem;
    margin-top: 0.8rem;
    overflow-wrap: anywhere;
  }
  .ars-net {
    margin-bottom: 0.8rem;
  }

  .ars-info {
    margin-top: 0;
  }
  .ars-field {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
  }
  .ars-inf-field {
    font-size: 0.9rem;
  }
  .ars-inf-subfield {
    font-size: 0.8rem;
    margin-left: 1rem;
    overflow-wrap: anywhere;
  }
  .description-container {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .ars-container {
    width: 92%;
  }
}
@media screen and (max-width: 568px) {
  .ars-container {
    width: 92%;
  }
  .purchase_btn {
    p {
      margin: 0.7rem;
    }
  }
}
