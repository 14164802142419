.slider-container {
  box-sizing: border-box;
  overflow: hidden;
  height: 44vh;
}

.slick-list {
  height: 100%;
}
.slick-dots {
  bottom: -20px;
}
.slider-item {
  position: relative;
  text-align: left;
  color: white;
  width: 100%;
  min-height: 42vh;
  background-color: #f0f0f0;
  transition: background-image 0.3s ease;
  .content {
    position: absolute;
    bottom: 8%;
    left: 5%;
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
    }

    p {
      font-size: 20px;
      font-weight: 300;
      display: inline;
    }
  }
}
.slick-next {
  right: 40px !important;
}
.slick-prev {
  left: 29px !important;
  z-index: 2;
}
.slick-prev:before,
.slick-next:before {
  font-size: 45px;
}
.artist-ava {
  width: 60px;
  height: 60px;
  background-color: #f0f0f0;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-item {
  .content {
    position: absolute;
    bottom: 8%;
    left: 5%;
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
    }

    p {
      font-size: 20px;
      font-weight: 300;
      display: inline;
    }
  }
}
.view-collection-button {
  position: absolute;
  bottom: 8%;
  right: 5%;
  background-color: white;
  color: black;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.view-collection-button:hover {
  background-color: #f0f0f0;
}

.slick-dots li button:before {
  color: white;
}

.slick-dots li.slick-active button:before {
  color: white;
}

.slider_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .slick-dots {
    bottom: -24px;
  }
  .slider-item .content,
  .view-collection-button {
    position: relative;
    margin-top: 5%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    left: 0;
  }
  .slider-item .content {
    width: 90%;
    h3 {
      font-size: 1.3rem;
    }
  }
  .view-collection-button {
    right: 0%;
    padding: 10px;
    font-size: 18px;
  }
  .slider-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.slick-dots {
  li {
    width: 15px;
    height: 15px;
    margin: 0 4px;

    .custom-dot {
      background-color: #fff;
      transition: background-color 0.3s ease;
    }

    &.slick-active .custom-dot {
      background-color: orange;
    }
  }
}
