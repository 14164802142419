.services-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 5% auto 0;
  position: relative;

  .services-tit {
    border-bottom: 1px solid #000;
    width: 78%;

    &::after {
      content: "";
      display: block;
      width: 180px;
      height: 3px;
      background-color: #000;
      margin-top: 5px;
    }
  }

  .services-title {
    font-size: 2rem;
    font-weight: bold;
    color: #000;
    position: relative;
    margin: 0;
  }

  .learn-more-btn {
    padding: 12px 20px;
    border: 1px solid #000;
    background-color: transparent;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;

    &:hover {
      background-color: #d3d3d3;
      box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.3);
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;

    .services-tit {
      width: 100%;

      &::after {
        width: 150px;
      }
    }

    .services-title {
      font-size: 1.8rem;
    }

    .learn-more-btn {
      margin-top: 15px;
      padding: 10px 18px;
      font-size: 0.9rem;
    }
  }

  @media (max-width: 768px) {
    .services-title {
      font-size: 1.5rem;
    }

    .learn-more-btn {
      padding: 10px 16px;
      font-size: 0.8rem;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    .services-tit {
      width: 100%;
      border-bottom: none;

      &::after {
        width: 120px;
        margin: 0 auto;
      }
    }

    .services-title {
      font-size: 1.2rem;
    }

    .learn-more-btn {
      padding: 8px 14px;
      font-size: 0.75rem;
      margin-top: 10px;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }
  }
}
