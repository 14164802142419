.userdata-block {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.userdata-container {
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1), 4px 0px 8px rgba(0, 0, 0, 0.2),
    -4px 0px 15px rgba(0, 0, 0, 0.02);
  border-radius: 0.7rem;
  margin: 4.7rem auto 2rem;
  width: 80%;
}
.userdata-content {
  width: 30%;
  margin: 1.5rem;
  display: flex;
}

.user-base-info {
  width: 100%;
  border-right: 3px solid #000;
  padding-right: 6%;
}

.user-name {
  color: #000;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.user-content-titles {
  color: #868686;
  font-weight: 600;
  font-size: 1.2rem;
}
.title-space {
  margin-top: 1rem;
}
.bullet {
  display: inline-block;
  background-color: #d9d9d9;
  width: 0.75em;
  height: 0.75em;
  border-radius: 1em;
  margin: 0.2rem;
}
.connect-button {
  background-color: black;
  color: white;
  padding: 10px 24px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: auto;
}

.bottom-section {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 20px;
  width: 80%;
  gap: 2%;
}

.notifications,
.coming-soon {
  background: #fff;
  padding: 1.5rem 2.5rem;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1), 4px 0px 8px rgba(0, 0, 0, 0.2),
    -4px 0px 15px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
}
.notifications {
  width: 28%;
}
.loa-interface {
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1), 4px 0px 8px rgba(0, 0, 0, 0.2),
    -4px 0px 15px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 72%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 17px;

  .loa-nav-btn{
    margin: 0 .5rem;
    border: 2px solid #000;
    border-radius: 10px;
    padding: .3rem;
    color: #000;
    font-weight: 600;
    margin-top: .5rem;
  }
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #000;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
.notifications .status {
  font-size: 14px;
}

.notifications .status.subscribed {
  color: #27ee56;
  font-weight: bold;
}
.subscribed_content {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.data-pack p {
  margin: 7px 0;
  color: #868686;
}
.wallet-address2 {
  font-size: 15px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  color: #000 !important;
}
.email {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: center;
}

.submit-button {
  padding: 10px 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
}
.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}
.close-div {
  text-align: right;
  margin: 0 0 10px;
}
.close-button:hover {
  color: red;
}
.e-mail,
.confirmation-code {
  padding: 10px;
  border-radius: 10px;
  width: 95%;
  margin: 20px auto;
}
.error-message {
  margin-bottom: 15px;
  color: red;
}

.buttons {
  margin: 20px 0;
}

.buttons2 button {
  padding: 10px 0px;
  width: 49%;
  border-radius: 10px;
  cursor: pointer;
}
.confirmation-connect-wallet {
  background-color: transparent;
  color: #000;
  border: 3px #000 solid;
  margin-right: 1%;
}
.confirmation-connect-wallet:hover {
  background-color: #000;
  color: #fff;
}
.confirmation-Confirm {
  background-color: #000;
  color: #fff;
}
.confirmation-Confirm:hover {
  background-color: #fff;
  color: #000;
  border: 3px #000 solid;
}

.userdata-container {
  display: flex;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.user-name {
  font-size: 20px;
  margin-bottom: 10px;
}

.wallet-address2 {
  font-family: monospace;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  border-radius: 8px;
}

.buttons2 {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .userdata-content {
    width: 100%;
  }
  .user-base-info {
    margin: 1rem;
    width: 85%;
  }
  .userdata-container {
    padding: 0;
    width: 90%;
  }
  .userdata-content {
    margin: 0;
  }
  .bottom-section {
    width: 90%;
    flex-direction: column;
    gap: 20px;
  }

  .notifications,
  .coming-soon {
    width: 90%;
    padding: 1rem;
  }
  .subscribed_content {
    display: flex;
    align-items: center;
  }

  .status {
    margin-right: 10px;
  }

  .toggle-switch input {
    width: 40px;
    height: 20px;
  }

  .coming-soon {
    font-size: 14px;
  }
  .connect-button {
    width: 90%;
  }
}
.status-message {
  color: green;
  margin-bottom: 15px;
}


.creator-requests{
  display: flex;
  width: 70%;
  justify-content: center;
  align-items: center;
}
@media (max-width: 790px) {
  .loa-interface{
    width: 90%;
  }
}