.category-bar-container {
  overflow: hidden;
  background-color: #000;
  padding: 10px 0;
  white-space: nowrap;
  width: 100%;
}

.category-bar {
  display: inline-flex;
  align-items: center;
  width: 100%;
  animation: scroll 15s linear infinite;
}

.category-item {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  margin: 0 10px;
}

.separator {
  font-size: 1.5rem;
  color: #fff;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20%);
  }
}
