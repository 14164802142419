.art-card {
  width: 320px;
  height: 350px;
  overflow-x: hidden;
  box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.7rem;

  background-color: #f7f7f7;
}

.art-card-image {
  width: 100%;
  height: 80%;
}

.art-card-title {
  color: #000;
}
.art-card-auth {
  color: #ffae45;
}

.art-card-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #000;
  margin: 0 auto;
  padding: 0;
}
.art-card-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 20%;
}
.card-author-image {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-avatar {
  border-radius: 50%;
  width: 3.3rem;
  height: 3.3rem;
}

.card-context {
  margin-left: 0.5rem;
}

@media screen and (max-width: 1440px) {
  .art-card {
    width: 260px;
    height: 280px;
  }
  .art-card-image {
    height: 75%;
  }
  .art-card-content {
    height: 25%;
  }
  .art-card-auth {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 1240px) {
  .art-card {
    width: 230px;
    height: 260px;
  }
}

@media screen and (max-width: 1120px) {
  .art-card {
    width: 220px;
    height: 240px;
  }
  .art-card-image {
    height: 75%;
  }
  .art-card-content {
    height: 25%;
  }
  .art-card-auth {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 568px) {
  .card-context {
    margin-left: 0;
  }
  .art-card-auth {
    display: none;
  }
  .card-avatar {
    width: 2rem;
    height: 2rem;
  }
  .art-card-title {
    font-size: 0.7rem;
  }
  .art-card {
    border-radius: 10px;
    width: 180px;
    height: 180px;
  }
}
@media screen and (max-width: 426px) {
  .categories-section {
    width: 90%;
  }
  .art-card {
    border-radius: 10px;
    width: 145px;
    height: 175px;
  }
}
@media (max-width: 1441px) {
  .art-card-title {
    font-size: 15px;
  }
}
