.article-slider-container {
  box-sizing: border-box;
  overflow: hidden;
  height: 44vh;
  .slick-list {
    overflow: visible;
    height: 100%;
  }
  .slick-slide div:first-child {
    height: 100%;
  }
  .article-content {
    height: auto !important;
  }
  .slick-dots {
    display: flex !important;
    justify-content: center;
    position: absolute;
    left: 85%;
    text-align: right;
    top: 6%;
    height: 10px;
    width: auto;
    list-style: none;
    li {
      display: inline-block;
      margin: 0 5px;
      button {
        &::before {
          content: "";
          display: block;
          width: 30px;
          height: 4px;
          background-color: white;
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .article-slider-container {
    .slick-dots {
      left: 50%;
    }
  }
}
@media screen and (max-width: 768px) {
  .article-slider-container {
    .slick-dots {
      display: none !important;
    }
  }
}

.article-slider-container {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.article-left-paragraph {
  position: absolute;
  bottom: 0%;
  left: 1%;
  width: 35%;
  color: white;
  font-size: 1rem;
  line-height: 1.6;
  padding: 15px;
  border-radius: 5px;
  text-align: left;
}

@media screen and (max-width: 1200px) {
  .article-left-paragraph {
    display: none;
  }
}

.article-slider-item {
  position: relative;
  text-align: left;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  transition: background-image 0.3s ease;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
  .article-content {
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
      color: #f2f2f2;
    }

    p {
      font-size: 20px;
      font-weight: 300;
      display: inline;
      color: #f2f2f2;
    }
  }
}
.article-left-paragraph p {
  color: #f2f2f2;
}

.article-slider-item .content,
.article-view-collection-button {
  position: relative;
  margin-top: 10%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  left: 0;
}
.article-slider-item .content {
  width: 90%;
  h3 {
    font-size: 1.3rem;
  }
}
.article-view-collection-button {
  right: 0%;
  padding: 10px 25px;
  font-size: 18px;
}
.article-slider-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.article-view-collection-button {
  bottom: 8%;
  right: 5%;
  background-color: white;
  color: black;
  padding: 10px 35px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.article-view-collection-button:hover {
  background-color: #f0f0f0;
}

.slick-dots li button:before {
  color: white;
}

.slick-dots li.slick-active button:before {
  color: white;
}

.article-slider_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  text-align: center;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .article-slider-item .article-content,
  .article-view-collection-button {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    left: 0;
  }

  .article-slider-item .article-content {
    width: 90%;
    h3 {
      font-size: 1.3rem;
    }
  }
  .article-view-collection-button {
    right: 0%;
    padding: 10px 25px;
    font-size: 18px;
  }
  .article-slider_content {
    width: 100%;
  }
}
@media screen and (max-width: 1370px) {
  .article-slider-item .article-content,
  .article-view-collection-button {
    margin-top: 8vh;
  }
}
.custom-slider-height {
  height: 100%;
}
.slick-track {
  height: 100%;
}
