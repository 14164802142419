.art-card-container {
  width: 80%;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: grid;
  flex-direction: wrap;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: clamp(0.5rem, 1vw, 1rem);
  min-height: 768px;
}
@media screen and (max-width: 1618px) {
  .art-card-container {
    width: 90%;
  }
}

@media screen and (max-width: 1440px) {
  .art-card-container {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

@media screen and (max-width: 1024px) {
  .media-home {
    margin-bottom: 17vh;
  }
  .art-card-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 710px) {
  .art-card-container {
    width: 95%;
    margin: 0 auto 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.articles-box-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1%;
  width: 85%;
  margin: 5% auto;

  /* Responsive Design */
  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
    width: 87%;
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
  }

  @media (max-width: 580px) {
    padding: 8px;
    grid-template-columns: repeat(1, 1fr);
  }
}
.articles-box-card {
  max-width: 350px;
}
