.portfolio-container {
  position: relative;
  width: 100vw;
  height: 88vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.swiper-container {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.portfolio-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
}

.swiper-slide-active .portfolio-image {
  animation: shrinkImage 3s ease-out forwards;
}

@keyframes shrinkImage {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.logo {
  position: absolute;
  top: 2vh;
  left: 2vw;
  font-size: clamp(16px, 2.5vw, 24px);
  font-weight: bold;
  color: white;
  z-index: 10;
}

.portfolio-text {
  position: absolute;
  font-size: clamp(18px, 3vw, 22px);
  font-weight: bold;
  color: white;
  text-align: center;
  z-index: 10;
  pointer-events: none;
}

.portfolio-text2 {
  font-size: clamp(2rem, 10vw, 6rem);
  font-weight: bold;
}

.social-icons {
  position: absolute;
  bottom: 2vh;
  left: 2vw;
  display: flex;
  gap: clamp(5px, 1vw, 10px);
  z-index: 10;
}

.social-icons svg {
  color: white;
  font-size: clamp(16px, 2vw, 20px);
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 5;
  pointer-events: none;
}

.play-button {
  position: absolute;
  bottom: 2vh;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  width: clamp(40px, 6vw, 60px);
  height: clamp(40px, 6vw, 60px);
  border-radius: 50%;
  overflow: hidden;
}

.play-button svg {
  font-size: clamp(16px, 2.5vw, 24px);
  color: white;
  z-index: 16;
}

.play-button::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px dashed white;
  border-radius: 50%;
  animation: spin 4s linear infinite;
  z-index: 14;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.play-button.paused::before {
  animation-play-state: paused;
}

.swiper-nav-buttons {
  position: absolute;
  bottom: clamp(20px, 5vh, 50px);
  display: flex;
  gap: clamp(5px, 1vw, 10px);
  z-index: 15;
}

.swiper-button-prev-custom,
.swiper-button-next-custom {
  width: clamp(30px, 4vw, 40px);
  height: clamp(30px, 4vw, 40px);
  background: #fff;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.swiper-button-prev-custom svg,
.swiper-button-next-custom svg {
  font-size: clamp(14px, 2vw, 20px);
  color: #333;
}

.swiper-button-prev-custom:hover,
.swiper-button-next-custom:hover {
  background-color: #f0f0f0;
}

@media (max-width:1024px) {
  .portfolio-container {
    height: 97vh;
  }
}

@media (min-width: 769px) {
  .swiper-nav-buttons {
    right: clamp(20px, 10vw, 130px);
  }
}

@media (max-width: 768px) {

  .swiper-button-prev-custom,
  .swiper-button-next-custom {
    width: 35px;
    height: 35px;
  }

  .swiper-button-prev-custom svg,
  .swiper-button-next-custom svg {
    font-size: 18px;
  }
}

@media (max-width: 480px) {

  .swiper-button-prev-custom,
  .swiper-button-next-custom {
    width: 30px;
    height: 30px;
  }

  .swiper-button-prev-custom svg,
  .swiper-button-next-custom svg {
    font-size: 16px;
  }
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: '';
  display: none;
}

@media (max-width: 768px) {
  .portfolio-text {
    font-size: clamp(16px, 2.5vw, 20px);
  }

  .portfolio-text2 {
    font-size: clamp(1.5rem, 8vw, 4rem);
  }

}

@media (max-width: 480px) {
  .portfolio-text {
    font-size: clamp(14px, 2vw, 18px);
  }

  .portfolio-text2 {
    font-size: clamp(1rem, 6vw, 3rem);
  }

  .swiper-button-prev-custom,
  .swiper-button-next-custom {
    width: 30px;
    height: 30px;
  }

  .play-button {
    width: 40px;
    height: 40px;
  }
}