.legal {
  min-height: calc(100vh - 5.4rem - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f7fa;
  padding-bottom: 40px;
}

.lgl-cover {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  position: relative;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.lgl-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 100%;
  height: 100%;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.5rem;
  letter-spacing: 0.8rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.legal-menu {
  width: 100%;
  background-color: #353535;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.legal-links {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  background-color: #353535;
  padding: 15px 0;

  p {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
    margin: 0.5rem 2rem;
    color: #ecf0f1;
    transition: color 0.3s ease;

    &:hover {
      color: #e74c3c;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.drp-bullet {
  transition: transform 0.3s ease;
  margin-right: 0.5rem;
  color: #e74c3c;
  font-size: 1.2rem;
}

.drp-bullet.open {
  transform: rotate(-90deg);
}

.dropdown-toggle {
  display: none;
  background-color: #353535;
  justify-content: center;
  align-items: center;
  color: #ecf0f1;
  border: none;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #353535;
  }

  @media (max-width: 768px) {
    display: flex;
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #353535;
  width: 100%;
  border: 1px solid #353535;
  z-index: 10;
  display: none;
  flex-direction: column;

  @media (max-width: 768px) {
    display: flex;
  }

  a {
    padding: 0.8rem;
    text-decoration: none;
    color: #ecf0f1;
    font-size: 0.9rem;
    text-align: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #34495e;
      color: #e74c3c;
    }
  }
}

.legal-content {
  width: 80%;
  margin: 3rem auto;
  background: #fff;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.lgcy-title {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid #e74c3c;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.lgcy-sub-title {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1.3rem;
  color: #e74c3c;
  padding-left: 10px;
  border-left: 4px solid #353535;
}

.lgcy-text {
  text-align: justify;
  font-size: 1rem;
  color: #555;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .lgl-cover {
    height: 150px;
  }
  .lgl-header {
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
  }
  .legal-content {
    width: 83%;
    margin: 1.5rem auto;
    padding: 1.5rem;
  }
  .lgcy-title {
    font-size: 1.1rem;
  }
  .lgcy-sub-title {
    font-size: 1.1rem;
  }
  .lgcy-text {
    font-size: 0.9rem;
  }
}
.term-number {
  font-weight: 700;
  color: #3498db;
  margin-right: 0.5rem;
  display: inline-block;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .term-number {
    font-size: 0.9rem;
    padding: 0.15rem 0.4rem;
  }
}

.sub-term-letter {
  font-weight: 700;
  color: #004d81;
  margin-right: 0.5rem;
  display: inline-block;
  padding: 0.1rem 0.4rem;
  border-radius: 3px;
  font-size: 0.95rem;
}

@media (max-width: 768px) {
  .sub-term-letter {
    font-size: 0.85rem;
    padding: 0.1rem 0.3rem;
  }
}

.multi-term-number {
  font-weight: 700;
  color: #025994;
  margin-right: 0.5rem;
  display: inline-block;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .multi-term-number {
    font-size: 0.9rem;
    padding: 0.15rem 0.4rem;
  }
}
