.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-video-rest {
  object-fit: cover;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
}

.about-cover {
  width: 100%;
  height: 280px;
}
.about-segment-border {
  margin: 2rem;
  width: 80%;
}
.serv_option_item {
  width: 24%;
}
.cmpny-serv-options {
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  gap: 2%;
}

.about-title {
  margin: 2rem 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.5rem;
}
@media screen and (max-width: 1024px) {
  .about-container {
    flex-direction: column;

    .image {
      width: 100%;
    }
    .about-image {
      height: 380px;
      object-fit: cover;
    }
  }
  .about {
    height: auto !important;
  }
  .cmpny-serv-options {
    margin: 1rem 0;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2%;
  }

  .serv_option_item {
    width: 49%;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .core-team-container {
    flex-direction: column;

    .team-description {
      width: 100%;
      padding-top: 1%;
    }
    .spider-diagram {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .about-image {
    height: auto;
  }
  .about {
    height: auto !important;
  }
  .Vision_container {
    flex-direction: column;

    .card {
      width: 80%;
      margin-top: 5%;
    }
  }
  .about-segment-border {
    margin: 1rem 0;
  }
  .about-title {
    font-size: 0.9rem;
    margin: 1rem 0;
  }
  .text-contain {
    font-size: 0.8rem;
  }
  .cmpny-serv-options {
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
  }

  .serv_option_item {
    width: 90%;
    margin-bottom: 1rem;
  }
}
.about-container {
  width: 85%;
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
  height: 100%;
  gap: 7%;
}
.about {
  width: 100%;
  padding: 37px 0;
  background-color: #e5e5e5;
  height: 48vh;
}
.image {
  width: 45%;
  height: 100%;
}
.about-image {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.about-content h2 {
  font-size: 22px;
  margin-bottom: 20px;
  color: #000;
}

.about-content p {
  font-size: 15px;
  line-height: 1.6;
  color: #000;
  max-width: 800px;
  margin: 0 auto;
  font-weight: 800;
}
.Vision_container {
  display: flex;
  justify-content: center;
  gap: 2%;
  width: 80%;
  margin: 3% auto 0;
}

.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 6px 8px rgb(0 0 0 / 30%);
  padding: 2% 9%;
  width: 50%;
  text-align: center;
  border: 1px solid #000;
  transition: all 0.3s ease-in-out;
}
.card:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-4px);
}
.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.text {
  font-size: 14px;
  font-weight: 800;
}
.core-team-container {
  text-align: center;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
  width: 100%;
}
.about-page2 {
  width: 80%;
  margin: 3% auto;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3% 0;
}
.core-team-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.core-team-structure {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.team-member {
  width: 200px;
}

.team-member.left {
  text-align: right;
}

.team-member.right {
  text-align: left;
}

.member-role {
  font-size: 12px;
  color: gray;
  margin-bottom: 15px;
}

.dot {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  display: inline-block;
  margin: 1.5px auto 0;
  cursor: pointer;
}

.filled {
  background-color: black;
}

.team-central {
  width: 100px;
  height: 100px;
  border: 3px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

.central-icon {
  width: 100%;
  height: 100%;
}

.team-description h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.team-description p {
  font-size: 14px;
  color: rgb(0, 0, 0);
  padding: 0 30px 0 0;
}

.team-description {
  text-align: left;
  padding-top: 5%;
  width: 40%;
  position: relative;
}

.arrow {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
}
.member-name {
  font-size: 14px;
  position: relative;
}

.team-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}
.left {
  .member {
    text-align: left;
  }
}
.right {
  .member {
    text-align: right;
  }
}

.connection-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  left: 0;
}

.team-description-content {
  display: flex;
}
.team-description_img {
  width: 80px;
  height: 80px;

  img {
    width: 100%;
    height: 100%;
  }
}

svg {
  width: 100%;
}
.spider-diagram {
  width: 60%;
  position: relative;
}

.team-description-text {
  padding: 20px 0;
}

.box-toggle {
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.box-toggle:hover {
  color: #000;
}

.arrow-icon {
  font-size: 35px;
  color: #000;
  transition: transform 0.6s ease-in-out;

  &:hover {
    color: #000;
  }

  &.rotated {
    transform: rotate(180deg);
  }
}

.team-content {
  text-align: center;

  p {
    text-align: left;
  }
}
.text-contain {
  width: 80%;
}

@media (max-width: 768px) {
  .core-team-container {
    flex-direction: column;
    align-items: center;
  }

  .spider-diagram {
    justify-content: center;
  }

  .team-description {
    width: 100%;
    margin-top: 20px;
  }

  .core-team-container .spider-diagram svg polyline,
  .core-team-container .spider-diagram svg circle {
    transform-origin: center;
  }
}

.team-description_img img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 720px) {
  .about-page2 {
    width: 100%;
    padding: 7% 0;
  }
  .team-description {
    width: 80% !important;
    padding: 10%;
  }
  .center-circle {
    r: 40;
  }
}
@media screen and (max-width: 719px) {
  svg {
    width: 60%;
  }
}
@media screen and (max-width: 560px) {
  svg {
    width: 80%;
  }
}
@media screen and (max-width: 460px) {
  svg {
    width: 90%;
  }
}
@media screen and (max-width: 360px) {
  svg {
    width: 100%;
  }
}
@media (max-width: 1150px) and (min-width: 1025px) {
  .image {
    width: 55%;
  }
}
