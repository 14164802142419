.home-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 100%;
  height: 88vh;
  .home-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 60%;
    margin: auto;

    h1 {
      font-size: 1.6rem;
      margin: 0;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
      margin: 0;
    }

    .button-group {
      display: flex;
      gap: 30px;

      .button-explore {
        border: #000 2px solid;
        padding: 10px 20px;
        font-size: 1rem;
        cursor: pointer;
        border: 2px #000 unset;
        color: #000;
        background-color: white;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);

        &:hover {
          background-color: #96969648;
          box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2);
        }
      }
      .span-about {
        font-size: 1rem;
        color: #000;
        cursor: pointer;
        text-decoration: underline;
        text-underline-offset: 6px;
        line-height: 37px;
      }
    }
  }

  .home-image {
    height: 100vh;

    img {
      width: 100%;
      height: inherit;
    }
  }

  @media (max-width: 1024px) {
    .home-content {
      width: 80%;
    }
    .home-image {
      height: inherit;
      img {
        height: inherit;
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-top: 10vh;
    height: auto;
    .home-content {
      width: 90%;
      text-align: center;
      height: 87vh;
      display: flex;
      justify-content: center;

      h1 {
        font-size: 1.8rem;
      }

      p {
        font-size: 0.9rem;
      }

      .button-group {
        flex-direction: column;
        gap: 15px;

        .button-explore {
          font-size: 0.9rem;
          padding: 8px 16px;
        }
        .span-about {
          font-size: 0.9rem;
        }
      }
    }

    .home-image {
      height: 50vh;
      img {
        height: inherit;
        object-fit: cover;
      }
    }
  }

  @media (max-width: 480px) {
    .home-content {
      h1 {
        font-size: 1.5rem;
      }

      p {
        font-size: 0.8rem;
      }

      .button-group {
        .button-explore {
          font-size: 0.8rem;
          padding: 6px 12px;
        }
        .span-about {
          font-size: 0.8rem;
        }
      }
    }

    .home-image {
      margin-top: 0%;
      img {
        height: 100%;
      }
    }
  }
}
