.user-greet {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #575757;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 1rem;
  text-align: center;
}
.auth-prompt {
  height: 74vh;
}
.user-content {
  align-items: center;
  width: 100%;
  min-height: 82vh;
}
.promt-mes {
  margin-left: 1rem;
  margin-top: 2rem;
}

.order-contain {
  width: 100%;
  display: flex;
  justify-content: center;
}

.order-history {
  width: 80%;
  margin: 1.7rem;
  box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow-x: hidden;
}
.order-element {
  border-bottom: 1px solid #808080;
}
.slideshow {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide.active {
  opacity: 1;
}


.request-placeholder{
  width: 80%;
}

.request-history{
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
@media (max-width: 790px) {
  .user-content{
    min-height: 86vh;
  }
}