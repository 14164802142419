.funooni-container {
  font-family: Arial, sans-serif;
  text-align: center;
  display: flex;
  width: 90%;
  margin: 5% auto;
}
.funooni_content {
  width: 70%;
}
.funooni-header {
  padding: 4%;
  width: 30%;
}
.funooni-header .logo {
  width: 70px;
}

.funooni-header h2 {
  font-size: 24px;
  margin: 5px 0 10px;
}

.funooni-header p {
  color: #555;
  font-size: 14px;
  padding: 0 5%;
}
.step_content p,
ul,
.optional-box p {
  font-size: 0.9rem;
}
.buttons {
  margin: 20px 0;
}

.buttons button {
  padding: 7px 0;
  width: 115px;
  margin: 0 10px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.connect-wallet {
  background-color: transparent;
  color: #000;
  border: 3px #000 solid;
}
.connect-wallet:hover {
  background-color: #000;
  color: #fff;
  transform: scale(1.05);
}
.login {
  background-color: #000;
  color: #fff;
}
.login:hover {
  background-color: #fff;
  color: #000;
  border: 3px #000 solid;
  transform: scale(1.05);
}
.funooni-steps {
  display: flex;
  justify-content: space-around;
  gap: 1%;
}

.step {
  width: 30%;
  padding: 35px 20px 25px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0), 4px 0px 8px rgba(0, 0, 0, 0.2),
    -4px 0px 15px rgba(0, 0, 0, 0.02);
}
.step_content {
  text-align: left;
}
.step img {
  width: 70px;
}

.step h3 {
  margin: 20px 0 5px;
}

.step a {
  color: #000;
  text-decoration: none;
}

.step ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}
.optional-section {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 2%;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0), 4px 0px 8px rgba(0, 0, 0, 0.2),
    -4px 0px 15px rgba(0, 0, 0, 0.02);
  padding: 15px;
}

.optional-box {
  flex: 1;
  text-align: left;
  font-size: 0.9rem;
}
.optional {
  justify-content: center;
  align-items: center;
  display: flex;
}
@media (max-width: 1024px) {
  .buttons button {
    margin: 10px;
  }
  .step h3 {
    font-size: 0.9rem;
  }
  .step_content p {
    font-size: 0.8rem;
  }
  .funooni-header {
    padding: 0;
  }
  .funooni-header {
    width: 25%;
  }
  .funooni_content {
    width: 75%;
  }
  .step {
    padding: 15px;
  }
}
@media (max-width: 768px) {
  .funooni-container {
    flex-direction: column;
    margin: 15% auto;
  }
  .funooni-header {
    width: 100%;
    padding: 0;
  }
  .funooni-steps,
  .optional-section {
    flex-direction: column;
  }
  .funooni_content {
    width: 100%;
  }
  .step {
    width: 100%;
    padding: 0;
    margin-top: 10px;
    img {
      padding-top: 25px;
    }
  }
  .step_content {
    padding: 20px;
  }
  .optional-box {
    margin-top: 5%;
  }
}
