.Footer {
  width: 100%;
  background: rgb(0, 0, 0);
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Footer-custom-height {
    height: 18vh;
  }
}
.footer-title {
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
}

.social-container {
  padding: 0.5rem 0;
  align-items: center;
  width: 80%;
  display: flex;
  justify-content: right;
  border-bottom: 1px solid #fff;
}
.inner-text {
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
  border-right: 1px solid #fff;
  margin-right: 0.5rem;
}
.social-list {
  display: flex;
  justify-content: right;
  margin: 1rem 0;
}
.social-item {
  align-items: center;
  display: flex;
}
.social-icon {
  margin-right: 0.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  color: white;
}
.social-name {
  color: #fff;
}

.footer-container {
  margin: 0.5rem 0;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.company-segment {
  display: flex;
  align-items: center;
}

.company-title {
  text-align: center;
  color: white;
  font-size: 1rem;
  text-transform: uppercase;
  padding-bottom: 0.1rem;
  letter-spacing: 0.4rem;
  border-bottom: 1px solid white;
}
.company-sub-title {
  padding-top: 0.2rem;
  font-size: 0.8rem;
  text-align: centersta;
  color: rgb(255, 187, 0);
}
.company-logo {
  margin: 0 1rem;
  width: 50px;
  height: 50px;
}

.footer-info {
  ul {
    @media (max-width: 768px) {
      display: none;
    }
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    li {
      margin: 0 1rem;
      a {
        color: white;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .company-title {
    font-size: 0.8rem;
  }
  .company-sub-title {
    font-size: 0.7rem;
  }
  .company-logo {
    margin: 0 0.5rem;
  }
  .footer-container {
    justify-content: center;
  }
  .social-container {
    padding: 0.6rem 0;
  }
  .social-list {
    margin: 0.2rem 0;
  }
  .footer-title {
    font-size: 0.8rem;
  }
  .social-name {
    font-size: 0.7rem;
  }
  .social-icon {
    margin-right: 0.2rem;
    width: 15px;
    height: 15px;
  }
  .inner-text {
    padding-right: 0.2rem;
    margin-right: 0.2rem;
  }
  .company-title-block {
    display: none;
  }
}
