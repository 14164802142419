.profile-details-page2 {
  padding: 4rem 0;
  min-height: 85vh;
  width: 100%;
  margin: 0 auto;
  background: #2d2d2d;
  overflow: hidden;
  position: relative;
}

.profile-detailss {
  width: 80%;
  margin: 0 auto ;
  position: relative;
  z-index: 2;
}

.photo-gallery2 {
  margin-top: 60px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.photo-gallery2 h3 {
  font-size: 2.5rem;
  font-family: "Arial", sans-serif;
  color: #f39c12;
  margin-bottom: 40px;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.photo-gallery2 h3 span {
  color: #fff;
  font-weight: bold;
  text-shadow: 0 2px 10px rgba(243, 156, 18, 0.5);
}

.photo-gallery2 h3::after {
  content: "";
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #f39c12, transparent);
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}

.gallery-grid2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 25px;
}

.gallery-item2 {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.gallery-item2:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
}

.gallery-image2 {
  width: 100%;
  height: 220px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 16px;
  transition: filter 0.3s ease;
  display: block;
  min-height: 220px; 
}

.gallery-item2:hover .gallery-image2 {
  filter: brightness(70%) contrast(1.1);
}

.gallery-overlay2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  color: #fff;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.gallery-item2:hover .gallery-overlay2 {
  opacity: 1;
  transform: translateY(0);
}

.gallery-overlay2 h4 {
  font-size: 1.2rem;
  margin: 0;
  font-weight: bold;
}

.gallery-overlay2 p {
  font-size: 0.9rem;
  margin: 5px 0 0;
  color: #f39c12;
}

.modal-overlay2 {
  position: fixed;
  top: 5rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.4s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-content2 {
  position: relative;
  max-width: 90%;
  max-height: 75vh;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  backdrop-filter: blur(15px);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5), 0 0 100px rgba(243, 156, 18, 0.2);
  animation: zoomIn 0.4s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

@keyframes zoomIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-image2 {
  width: 100%;
  max-height: 60vh;
  object-fit: contain;
  border-radius: 12px;
  filter: brightness(1.1) contrast(1.05);
}

.modal-info2 {
  padding: 20px;
  color: #fff;
  width: 100%;
}

.modal-info2 h4 {
  font-size: 1.8rem;
  margin: 0 0 10px;
  color: #f39c12;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.modal-info2 p {
  font-size: 1rem;
  line-height: 1.6;
  margin: 0 0 20px;
  color: #ddd;
}

.modal-actions2 {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.close-button2 {
  position: absolute;
    top: 4px;
    right: 2px;
    font-size: 2rem;
    background: rgba(231, 76, 60, 0.9);
    border: none;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.close-button2:hover {
  background: #c0392b;
  transform: scale(1.1) rotate(90deg);
  box-shadow: 0 5px 15px rgba(231, 76, 60, 0.6);
}

.details-btn2 {
  display: inline-block;
  padding: 12px 35px;
  background: linear-gradient(135deg, #e08c00, #f39c12);
  color: #fff;
  text-decoration: none;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(243, 156, 18, 0.4);
}

.details-btn2:hover {
  background: linear-gradient(135deg, #e08c00, #bd7500);
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(243, 156, 18, 0.6);
}

.buy-btn2 {
  display: inline-block;
  padding: 12px 35px;
  background: linear-gradient(135deg, #e74c3c, #c0392b);
  color: #fff;
  text-decoration: none;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(231, 76, 60, 0.4);
}

.buy-btn2:hover {
  background: linear-gradient(135deg, #c0392b, #a93226);
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(231, 76, 60, 0.6);
}

@media (max-width: 768px) {
  .photo-gallery2 h3 {
    font-size: 2rem;
  }

  .gallery-grid2 {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }

  .gallery-image2 {
    height: 180px;
    min-height: 180px;
  }

  .modal-content2 {
    padding: 20px;
  }

  .modal-image2 {
    max-height: 50vh;
  }

  .modal-info2 h4 {
    font-size: 1.5rem;
  }

  .modal-info2 p {
    font-size: 0.9rem;
  }

  .modal-actions2 {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .gallery-image2 {
    height: 150px;
    min-height: 150px;
  }

  .modal-info2 h4 {
    font-size: 1.2rem;
  }

  .modal-info2 p {
    font-size: 0.8rem;
  }
}