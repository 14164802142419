.photo-detail {
  padding: clamp(6rem, 6vw, 5rem) clamp(1rem, 2vw, 2rem);
  background: #2d2d2d;
  color: #fff;
  min-height: 100vh;
}

.photo-details2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.photo-detail h1 {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  font-weight: bold;
  color: #f39c12;
  margin-bottom: clamp(1rem, 2vw, 2rem);
  text-align: center;
}

.photo-image-container {
  width: 100%;
  max-width: 800px;
  margin-bottom: clamp(1rem, 2vw, 2rem);
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.photo-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.photo-image:hover {
  transform: scale(1.02);
}

.photo-info {
  width: 100%;
  max-width: 760px;
  background: rgba(255, 255, 255, 0.1);
  padding: clamp(1rem, 2vw, 2rem);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.photo-info p {
  font-size: clamp(0.9rem, 1.5vw, 1.2rem);
  line-height: 1.8;
  margin: 0.5rem 0;
  color: #e0e0e0;
}

.photo-info strong {
  color: #f39c12;
  font-weight: 600;
}

.back-button2 {
  display: inline-block;
  width: 100%;
  padding: .7rem 0;
  background: #f39c12;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  font-weight: bold;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.back-button2:hover {
  background: #e08c00;
}

.photo-detail-container {
  width: 85%;
  margin: 0 auto;
}

.photo-details3 {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  max-width: 1600px;
  background: rgba(255, 255, 255, 0.1);
  padding: clamp(1rem, 2vw, 2rem);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  align-items: normal;
  margin: auto;
  gap: 18px;
}

@media (max-width: 768px) {

  .photo-detail h1 {
    font-size: clamp(1.2rem, 3vw, 2rem);
  }

  .photo-image-container {
    max-width: 100%;
  }

  .photo-info,
  .photo-details3 {
    padding: clamp(0.8rem, 1.5vw, 1.5rem);
  }

  .photo-info p {
    font-size: clamp(0.8rem, 1.2vw, 1rem);
  }

  .photo-details2 {
    flex-direction: column;
  }

  .photo-details3 {
    margin-top: 2rem;
  }
}

@media (max-width: 480px) {

  .photo-detail h1 {
    font-size: clamp(1rem, 2.5vw, 1.5rem);
  }

  .photo-info p {
    font-size: clamp(0.7rem, 1vw, 0.9rem);
    line-height: 1.6;
  }
}