.MeetArtist-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 55vh;
  width: 90%;
  margin: auto;
  margin-bottom: 5%;

  .MeetArtist {
    width: 100%;
    height: inherit;
    background-color: #e2e0e0d5;
    display: flex;
  }

  .MeetArtist-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 80%;
    margin: auto;
    align-items: center;

    h1 {
      font-size: 2rem;
      margin: 0;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
      margin: 0;
    }

    .button-group {
      display: flex;
      gap: 30px;
      justify-content: center;

      .button-explore {
        padding: 8px 35px;
        font-size: 1rem;
        cursor: pointer;
        border: 2px #000 ridge;
        color: #000;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);

        &:hover {
          background-color: #96969648;
          box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .MeetArtist-image {
    height: inherit;

    img {
      width: 100%;
      height: 100%;
    }
  }

  /* Responsive Design */
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    height: auto;

    .MeetArtist {
      padding: 10% 0;
    }
    .MeetArtist-content {
      h1 {
        font-size: 1.8rem;
      }

      p {
        font-size: 0.9rem;
      }
    }

    .MeetArtist-image img {
      height: auto;
    }
  }

  @media (max-width: 768px) {
    .MeetArtist-content {
      h1 {
        font-size: 1.6rem;
      }

      p {
        font-size: 0.85rem;
      }

      .button-group .button-explore {
        padding: 8px 25px;
        font-size: 0.9rem;
      }
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    text-align: center;
    margin-top: 35%;
    .MeetArtist {
      height: 40vh;
    }
    .MeetArtist-content {
      h1 {
        font-size: 1.4rem;
      }

      p {
        font-size: 0.8rem;
      }

      .button-group .button-explore {
        font-size: 0.8rem;
        padding: 6px 20px;
      }
    }

    .MeetArtist-image img {
      width: 100%;
      height: auto;
    }
  }
}
