:root {
  --font-family: "Montserrat", sans-serif;
}

*,
body,
html {
  margin: 0;
  padding: 0;
}

html {
  font-family: var(--font-family);
  // margin: 0;
  // padding: 0;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body,
html {
  overflow-x: hidden;
}

p {
  margin: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.toast {
  margin-top: 7%;

  button {
    width: 10%;
  }
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  z-index: 9999;

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 81%; /* Для текста */
    line-height: 1.5;

    p {
      font-size: 14px;
      color: #333;
      margin: 0 0 10px;
    }

    a {
      color: #000000;
      text-decoration: none;
      font-weight: 600;
      border-bottom: 1px solid #000;
      &:hover {
        color: #ff5e00;
        border-bottom: 1px solid #ff5e00;
      }
    }
  }

  &__actions {
    display: flex;
    gap: 10px;
    align-items: center;
    button {
      font-size: 14px;
      padding: 8px 16px;
      border-radius: 4px;
      border: 1px solid transparent;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      }
    }

    button:first-child {
      background-color: transparent;
      color: #333;
      border: 1px solid #e5e5e5;

      &:hover {
        background-color: #f8f9fa;
      }
    }

    button:last-child {
      background-color: #1b1b1b;
      color: #fff;

      &:hover {
        background-color: #000000;
      }
    }
  }
}
