.Fashion {
  font-family: Arial, sans-serif;
  color: #333;
}

.start_h1 {
  font-size: 1.8rem;
}

.header {
  position: relative;
  background: url("../../assets/images/Fashion/img5.png") no-repeat center
    center/cover;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.header_content {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
}

.header-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-button {
  display: flex;
  color: rgb(0, 0, 0);
  border: none;
  margin-top: 0.6rem;
  cursor: pointer;
  font-size: 0.9rem;
}

.start-inner {
  border-radius: 25px;
  background-color: white;
  padding: 10px 35px;
}

.prod-items {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.start-button:hover,
.create-btn:hover {
  transform: scale(1.02);
}
.container_paragraph {
  width: 66%;
  margin: auto;
  margin-bottom: 70px;
  p,
  h3 {
    margin-bottom: 18px;
  }
  a {
    font-weight: bold;
    color: #000;
  }
}
.products,
.features {
  display: flex;
  justify-content: center;
  gap: 3%;
  margin: 3% auto;
  width: 66%;
}

.product-item {
  width: 32%;
  height: 47vh;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
  }
}

.new_about {
  text-align: center;
}

.new_about h2 {
  margin-bottom: 20px;
}

.features {
  display: flex;
  justify-content: space-between;
}

.feature {
  width: 29%;
  text-align: center;
}

.feature {
  h3 {
    margin: 20px 0;
  }
  img {
    width: 130px;
    height: auto;
    margin-bottom: 17px;
  }
}

.art-gallery {
  display: flex;
  justify-content: flex-start;
}

.gallery-item {
  height: 42vh;
  width: 25%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.gallery-container {
  text-align: left;
  width: 83%;
  margin: auto auto 40px;
}

.gallery-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}

.card_content {
  color: #000;
}
.create-btn {
  background: black;
  color: white;
  border: none;
  padding: 12px 40px;
  cursor: pointer;
  border-radius: 12px;
}
.artwork-card {
  position: relative;
  width: 32%;
  height: 300px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease-in-out;
  margin-bottom: 2%;
}

.overlay_y {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  color: white;
  top: 0;
}

.overlay_y h3 {
  text-align: center;
  font-size: 1.2em;
}

.card-footer {
  width: 100%;
  background: white;
}
.artwork-card:hover .overlay_y,
.product-item:hover .overlay_y {
  opacity: 1;
}
.overlay_y_title {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-footer-content {
  padding: 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.overlay_y_title2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: rgba(151, 151, 151, 0.45);
  .title_content {
    margin-bottom: 40px;
    margin-top: 30px;
  }
  .h3 {
    margin-top: 5px;
    font-weight: bold;
    font-size: 1.6rem;
  }
  .create-btn {
    padding: 14px 50px;
    border-radius: 22 px;
    font-size: 1rem;
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .products,
  .features,
  .art-gallery {
    flex-direction: column;
    align-items: center;
  }
  .products {
    gap: 10px;
  }

  .product-item,
  .feature,
  .gallery-item {
    width: 100%;
  }
  .feature {
    margin-bottom: 35px;
  }
  .products,
  .features,
  .container_paragraph {
    width: 83%;
  }
  .artwork-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .card-footer-content {
    flex-direction: column;
    align-items: center;
  }
  .create-btn {
    margin-top: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .products,
  .features,
  .art-gallery {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .product-item,
  .feature,
  .artwork-card {
    width: 48%;
    margin-bottom: 20px;
  }
  .gallery-item {
    margin-bottom: 0px;
    width: 50%;
  }
  .products,
  .features,
  .container_paragraph {
    width: 80%;
  }
}

@media (min-width: 1025px) and (max-width: 1300px) {
  .product-item {
    height: 30vh;
  }
}
.fashion-container {
  margin: 100px auto;
  text-align: center;
}
.fashion-card-container {
  .fashion-card-left {
    display: flex;
  }
  .container-qr-code {
    border-bottom: 1px black solid;
    margin-bottom: 30px;
    .qr-code {
      width: 100%;
      height: 200px;
      background-size: cover;
      background-position: center;
      margin: 0 auto 20px auto;
    }
  }

  .hoodie-image {
    width: 100%;
    height: 366px;
    background-size: cover;
    background-position: center;
  }

  .fashion-details {
    .fashion-item {
      display: flex;
      margin-bottom: 10px;

      span {
        font-size: 13px;
      }
      span:first-child {
        font-weight: bold;
        margin-right: 6px;
      }
    }
  }
}
.fashion-btn {
  padding: 10px;
  border: 1px #96969680 solid;
  background-color: white;
  color: black;
  width: 160px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
}
.fashion-btn:hover {
  background-color: black;
  color: white;
}
.fashion-actions {
  display: flex;
  gap: 2%;
  justify-content: center;
  margin: 20px auto;
  width: 85%;
  padding: 0;
  .fashion-btn {
    width: 120px;
    padding: 10px;
    font-size: 15px;
    border: 1px #96969680 solid;
    border-radius: 10px;
    cursor: pointer;
  }
  .black {
    background-color: black;
    color: white;
  }
  .black:hover {
    background-color: white;
    color: black;
  }
  .white {
    background-color: white;
    color: black;
  }
  .white:hover {
    background-color: black;
    color: white;
  }
}
.container-fashion-category {
  width: 83%;
  margin: auto;

  .fashion-card-container {
    display: grid;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 20px auto;
    background-color: #fff;
    width: 100%;
    gap: 1%;
    margin-top: 60px;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;

    @media (max-width: 1300px) {
      grid-template-columns: repeat(3, 1fr);
      width: auto;
    }

    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 580px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 8px;
    }

    .fashion-card-left,
    .fashion-card-right {
      height: 460px;
      margin-bottom: 30px;
      border: 1px solid #ddd;
      width: 100%;
      flex-direction: column;
      transition: box-shadow 0.3s ease;
      box-shadow: 8px 10px 10px 0 rgba(0, 0, 0, 0.138),
        0 12px 10px rgba(0, 0, 0, 0.06), 0 10px 20px rgba(0, 0, 0, 0.08);
    }
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
}
.fashion-card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left {
  padding: 27px;
}
.fashion-details {
  height: 108px;
}
.fashion-category-buttons {
  display: flex;
  margin-bottom: 20px;
}

.category-btn {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  background-color: #fff;
  border: 2px solid black;
  transition: all 0.3s ease;
}

.category-btn.active {
  background-color: black;
  color: white;
}

.question-mark {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background-color: white;
  border: 1px rgba(105, 105, 105, 0.502) solid;
  cursor: pointer;
  margin-left: 47px;
  margin-top: 12px;
}

.question-mark:hover {
  background-color: #eaeaea;
}

.fashion-card-left {
  opacity: 0;
  visibility: hidden;
  display: none;
}

.fashion-card-left.show {
  opacity: 1;
  visibility: visible;
}

.fashion-card-right {
  position: relative;
  z-index: 5;
}

.fashion-card-left.hidden,
.fashion-card-right.hidden {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.fashion-card-left.show,
.fashion-card-right.visible {
  opacity: 1;
  pointer-events: all;
  display: flex;
}
.fashion-card-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.fashion-details {
  flex-grow: 1;
  overflow: hidden;
}
.fashion-actions-2 {
  display: flex;
  justify-content: space-between;
  gap: 2%;
  justify-content: center;
  margin: 0 auto;
  width: 85%;
  padding: 0;
}
