.testimonials {
  background: #f9f9f9;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-container2 {
  width: 80%;
  margin: 8rem auto;
  text-align: center;
}

.gallery-title {
  font-size: 2.5rem;
  color: #34495e;
  margin-bottom: 40px;
  font-family: "Arial", sans-serif;
  position: relative;
}

.gallery-title::after {
  content: "";
  width: 60px;
  height: 3px;
  background: linear-gradient(to right, #ff6200, #fff);;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.photographers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.photographer-item {
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.photographer-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

.photographer-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
}

.photographer-info {
  padding: 15px;
  background: #fff;
  border-radius: 0 0 12px 12px;
  text-align: center;
}

.photographer-name {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: bold;
}

.explore-button {
  padding: 10px 25px;
  background: linear-gradient(135deg, #e08c00, #f39c12);
  color: #fff;
  border-radius: 25px;
  font-size: 1.1rem;
  font-weight: bold;
  transition: all 0.3s ease;
}

.explore-button:hover {
  background: linear-gradient(135deg, #e08c00, #bd7500);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .photographers-grid {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .gallery-title {
      font-size: 2rem;
  }

  .photographer-name {
      font-size: 1.3rem;
  }
}