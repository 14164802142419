.application {
  display: flex;
  justify-content: center;
  height: 88vh;
  background: #e0e0e0;
}

.profile-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.profile-card {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
  cursor: pointer;
  transition: filter 0.5s ease;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.5s ease;
  display: block;
  min-height: 400px;
}

.profile-card:hover .profile-image {
  filter: brightness(50%);
}

.hover-details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name-container {
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: Arial, sans-serif;
  transition: transform 0.5s ease;
  margin-bottom: 25px;
}

.name-container.hovered {
  transform: translateY(-20px);
}

.details-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.5s ease forwards;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.details-content p {
  margin: 0 0 20px 0;
  font-size: 16px;
  font-family: Arial, sans-serif;
  line-height: 1.7;
}

.details-content button {
  position: relative;
  padding: 15px 23px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  width: fit-content;
  overflow: hidden;
  margin: 25px 0;
}

.c-btn.inverse {
  color: #111;
  background: #fff;
}

.c-btn.inverse-dark {
  color: #fff;
  background: #111;
}

.c-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: orange;
  transform-origin: right center;
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.c-btn:hover::before {
  transform-origin: left center;
  transform: scale(1, 1);
}

.c-btn span {
  display: inline-block;
  position: relative;
  z-index: 2;
}

.c-btn:hover {
  color: #fff;
}

.modal-overlayy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-contentt {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.detailed-view {
  display: flex;
  width: 100%;
  height: 100%;
}

.detailed-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detailed-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  min-height: 400px;
}

.detail-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 60%;
  height: 100%;
  margin: auto;
}

.detailed-text {
  flex: 1;
  background: #fff;
}

.detail-text h2 {
  margin-top: 0;
  font-size: 30px;
}

.detail-text p {
  font-size: 16px;
  line-height: 1.8;
  border-left: 1px #a6a6a6 solid;
  padding: 0 50px;
  margin: 20px 0;
}

.detail-text button {
  position: relative;
  padding: 15px 23px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  width: fit-content;
  overflow: hidden;
}

.detail-text button:hover {
  background-color: #333;
}

.slide-from-left {
  animation: slideFromLeft 0.5s ease forwards;
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.slide-from-right {
  animation: slideFromRight 0.5s ease forwards;
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.swiper-slide {
  transform: none !important;
  width: 430px;
}

.swiper-button-prev-custom,
.swiper-button-next-custom {
  width: 40px;
  height: 40px;
  background: #fff;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.swiper-button-prev-custom {
  left: 10px;
}

.swiper-button-next-custom {
  right: 10px;
}

.swiper-button-prev-custom svg,
.swiper-button-next-custom svg {
  font-size: 20px;
  color: #000;
}

.swiper-button-prev-custom:hover,
.swiper-button-next-custom:hover {
  background-color: #f0f0f0;
}

@media (max-width: 1024px) {
  .detail-text p {
    font-size: 15px;
    line-height: 1.3;
    padding: 5px;
    margin: 0;
  }

  .detail-text {
    width: 85%;
  }

  .detail-text h2 {
    font-size: 25px;
  }
}

@media (max-width: 600px) {
  .application {
    height: 92vh;
  }

  .modal-contentt {
    display: block;
  }

  .detail-text p {
    font-size: 12px;
    line-height: 1.3;
    padding: 2px;
    margin: 0;
  }

  .detail-text {
    width: 85%;
  }

  .detail-text h2 {
    font-size: 25px;
  }

  .profile-image,
  .detailed-image {
    min-height: 300px;
  }
}