.NavBar {
  &.custom-height {
    height: 13vh;
  }
  &.custom-height2 {
    height: 12vh;
  }
}
@media (min-width: 1024px) and (min-height: 730px) {
  .NavBar {
    &.custom-height2 {
      height: 9vh;
    }
  }
}

.nav-bar-buf {
  box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.nav-container {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-upper {
  margin: 0.3rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  text-decoration: none;
}

.link-style {
  text-decoration: none;
}

.nav-stroke {
  width: 100%;
  background: rgb(0, 0, 0);
  align-items: center;
  justify-content: center;
  display: flex;

  .nav-link-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-point {
    margin: 0.4rem 2rem;
  }
}

.nv-link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 200;

  &.type1 {
    color: #000;
  }

  &.type2 {
    color: #ffffff;
  }
}

.active-link {
  color: #ff9900;
  font-weight: bold;
}

.nav-logo {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
}

.inter_active_icon {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.nav-company_title {
  color: #6f6f6f;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 127.523%;
  letter-spacing: 0.19688rem;
  margin: 0.3rem 0;
}

.nav-company_title {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.8s 0.2s, transform 0.8s;
}

.scrolled .nav-company_title {
  opacity: 0;
  transform: translateY(-100%);
}

.inter_active_icon:hover .nav-company_title {
  opacity: 1;
  transform: translateY(0);
}

.pop_link-btn {
  text-align: center;
  width: 100%;
}

.pop-up-text {
  font-size: 1.2rem;
  color: #6e6e6e;
  padding: 0.5rem;

  &:hover {
    background-color: #6e6e6e85;
    color: #fff;
  }
  &.logout {
    &:hover {
      color: #ff0000;
      background-color: #ff939385;
    }
    color: #ff5151;
  }
}

.awd {
  .nav-stroke {
    position: relative;
    background: linear-gradient(90deg, #ff6b6b, #007bff);
    background-size: 0% 100%;
    background-repeat: no-repeat;
    transition: background-size 0.3s ease-in-out;

    &:hover {
      background-size: 100% 100%;
    }
  }
}

.popup-mainmenu {
  width: 300px;
  right: 20px;
  top: 1rem;
  border-radius: 1rem;
}

.overlay {
  display: none;
  width: 100%;
  height: 100%;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(5px);
  transition: background-color 2.3s ease;
}

.overlay.active {
  background-color: rgba(0, 0, 0, 0.5);
  /* Конечная прозрачность */
}
.connect-metamask-div {
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.connect-metamask-btn {
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 7px 16px;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    background-color: #e2e2e2;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: #ccc;
    box-shadow: none;
  }
}
@media (min-width: 4300px) {
  .NavBar.custom-height2 {
    height: 0;
  }
}

@media screen and (max-width: 1024px) {
  .NavBar {
    &.custom-height2 {
      height: 6vh;
    }
  }
  .nav-point {
    margin: 0.4rem 1rem !important;
  }
}
@media screen and (max-width: 780px) {
  .NavBar {
    &.custom-height {
      height: 10vh;
    }
    &.custom-height2 {
      height: 7vh;
    }
  }
  .nav-point {
    margin: 0.4rem 0.5rem !important;
  }
  .nav-link-container {
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .overlay {
    display: block;
  }

  .popup-mainmenu {
    top: 0;
  }

  .pop-up-text {
    padding: 1rem 0;
    font-weight: 600;
  }

  .pop_link-btn {
    border-bottom: 2px solid #c2c2c2;
  }

  .NavBar {
    &.custom-height {
      height: 7vh;
    }
    &.custom-height2 {
      height: 6vh;
    }
  }
  .nav-link-container {
    width: 100%;
    height: 0.5rem;
    overflow-x: auto;
    white-space: nowrap;
  }

  .overlay .active {
    background-color: rgba(0, 0, 0, 0);
  }

  .popup-mainmenu {
    border-radius: 0;
    width: 100%;
    right: 0;
  }

  .nav-bar-buf {
    overflow-x: hidden;
  }

  .nav-point {
    display: none;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .mv1 {
    display: none;
  }
  .burger-menu {
    display: flex;
    width: 100%;
    justify-content: end;
    gap: 2%;
  }
}

@media screen and (min-width: 1025px) {
  .mv1 {
    display: none;
  }
  .burger-menu {
    display: flex;
    width: 50%;
    justify-content: end;
    gap: 2%;
  }
}
.epKvwk {
  margin-left: 1%;
}

@media screen and (max-width: 768px) {
  .nav-logo {
    height: 20px;
    width: 20px;
  }
  .connect-metamask-div {
    display: none;
  }
  .nav-company_title {
    font-size: 1.5rem;
  }
  .burger-menu {
    display: flex;
  }
  .connect-metamask-btn,
  .wallet-address {
    padding: 6px 13px;
    font-size: 12px;
  }
}

.wallet-address {
  border: 2px solid black;
  font-family: Arial, sans-serif;
  font-size: 14px;
  text-align: center;
  background-color: transparent;
  border-radius: 10px;
  padding: 7px 16px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .wallet-address {
    padding: 6px 4px;
  }
}
@media (max-height: 480px) {
  .NavBar.custom-height2 {
    height: 16vh;
  }
}
@media (max-height: 480px) and (max-width: 600px) {
  .NavBar.custom-height2 {
    height: 13vh;
  }
}
