.letter-container {
  display: flex;
  align-items: center;
  min-height: 100vh;
  margin: 0 auto;
}

.header-container {
  display: flex;
  gap: 20px;
  min-height: 50vh;
  margin-bottom: 50px;
}

.letter-image-section,
.header-section {
  flex: 1;
}

.header-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.letter-image-section {
  background: rgb(224, 224, 224);
  justify-content: center;
  align-items: center;
  display: flex;
  height: inherit;
  padding: 10px 0;
}
.img {
  width: 60%;
  height: inherit;
}
.letter-image {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.letter-content {
  width: 80%;
  margin: auto;
}
.letter-title-under {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.letter-author {
  color: #ff9318;
}

.letter-header {
  margin-bottom: 20px;
}
.letter-ars-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 2px solid #000;
}
.letter-title {
  font-size: 1.9rem;
  color: #333;
  margin: 0;
}

.letter-subtitle {
  font-size: 1.2rem;
  color: #ff4500; /* Цвет как на изображении */
  margin: 5px 0;
}

.letter-description {
  font-size: 1rem;
  line-height: 1.5;
}
.Description {
  font-weight: bold;
  margin-bottom: 10px;
}
.letter-certificate-section,
.letter-details-section,
.letter-history-section {
  background: #fff;
  border: 1px solid #ddd;
  padding: 20px 30px;
  width: 78%;
  margin: 20px auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}
.l-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.letter-certificate-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  justify-content: space-between;
}
.first-letter-certificate-details {
  border-bottom: #b4b4b4 1px solid;
  padding-bottom: 20px;
}
.letter-certificate-details {
  flex: 2;
}
.letter-logo {
  width: 80px;
  height: 80px;
}
.Signed-img {
  width: 60px;
  height: 60px;
  margin-right: 50px;
}
.letter-certificate-title {
  font-size: 1.5rem;
  margin: 0;
}
.verified {
  display: flex;
  flex-flow: row-reverse;
  gap: 70px;
  margin-top: 40px;
}
.first-verified,
.second-verified {
  display: flex;
  justify-content: center;
  align-items: center;
}
.letter-certificate-subtitle {
  font-size: 1rem;
  color: #666;
  margin: 0;
}

.letter-certificate-content {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}
.letter-p-content {
  color: #ff9318;
}
.div-letter-certificate-image {
  flex: 1;
  display: flex;
  align-items: center;
}
.letter-certificate-image {
  width: 80%;
  height: auto;
}

.letter-p {
  margin: 13px 0;
  display: flex;
  gap: 1rem;
  text-align: end;
  justify-content: space-between;
}
.letter-p span {
  overflow-wrap: anywhere;
}
.letter-upload {
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  display: none;
}
.letter-Digital {
  background: #cacaca;
  color: #333;
  border: 1px solid #757575;
  padding: 10px 20px;
  border-radius: 11px;
  margin-top: 20px;
}

.letter-upload:hover {
  background: #2d2d2d;
}

.letter-subtitle {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: #000 2px solid;
}

.letter-learn-more {
  background: #ff4500;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
.history {
  width: 80%;
  text-align: center;
  margin: 50px auto 0px;
  padding: 20px 0 0;
  color: #b1b1b1;
  border-top: #b4b4b4 1px solid;
}
.letter-preview {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;

  @media (min-width: 768px) {
    height: 493px;
    min-width: 40%;
    max-width: 100%;
    margin-bottom: 0;
  }
}
.letter-preview-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: inherit;
}

@media (max-width: 768px) {
  .header-container,
  .letter-certificate-content {
    display: flex;
    flex-direction: column !important;
  }
  .letter-certificate-image {
    margin: auto;
  }
  .letter-container {
    margin-top: 1.4rem;
    display: flex;
    justify-content: center;
  }
  .header-container {
    height: 100%;
  }
  .letter-image-section {
    height: 310px;
  }
}
@media print {
  .hide-in-pdf {
    display: none !important;
  }
}
.letter-content-wrapper {
  margin: 0 auto;
  width: 100%;
}
