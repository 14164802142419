.authenticity-section {
  background-color: #000;
  color: #fff;
  padding: 40px 20px 10px;
  justify-content: center;
  align-items: stretch;
  min-height: 300px;
  transition: all 0.3s ease;
  width: 100%;
}

.authenticity-content {
  width: 90%;
  gap: 20px;
  transition: all 0.3s ease;
  margin: auto;
}

.image-container {
  text-align: center;
  align-items: center;
}

.horse-image {
  max-width: 230px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.text-container {
  width: 38%;
  padding: 0 20px 0 65px;
  transition: all 0.3s ease;
  border-right: 2px #fff solid;
}

.text-container h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.text-container p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}
.authenticity-container {
  display: flex;
  gap: 3rem;
  align-items: center;
}
.read-more {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  display: block;
  border-top: 2px #fff solid;
  width: 95%;
  margin: 3rem auto 0;
  text-align: center;
  padding: 10px 0;
}

.expanded-content {
  display: flex;
  border-top: 2px #fff solid;
  padding-top: 30px;
  margin: auto;
  margin-top: 40px;
  animation: slideIn 0.3s ease;
  width: 95%;
  gap: 3rem;
}

.expanded-content h3 {
  font-size: 20px;
  margin-bottom: 30px;
}

.how-it-works,
.setup-process {
  margin: 0 0 20px 20px;
}
.how-it-works {
  list-style: disc;
}
.how-it-works li,
.setup-process li {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.how-it-works li strong,
.setup-process li strong {
  font-weight: bold;
}

.hide-button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.hide-button:hover {
  background-color: #e0e0e0;
}

.pricing-container {
  flex: 1;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
}

.price {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.features-list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.features-list li {
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 1.3;
  text-align: left;
}
.span {
  display: flex;
}
.check-mark {
  color: #000;
  margin-right: 10px;
  background: white;
  border-radius: 50%;
  padding: 8px 10px;
  height: 18px;
}

.upgrade-button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upgrade-button:hover {
  background-color: #e0e0e0;
}
@media (max-width: 1300px) {
  .text-container {
    padding: 0 5px 0 0;
  }
  .authenticity-container {
    gap: 2rem;
  }
}
@media (max-width: 920px) {
  .authenticity-container {
    flex-direction: column;
  }
  .text-container {
    width: 100%;
    padding: 0%;
    border: none;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .authenticity-content,
  .authenticity-container,
  .expanded-content {
    flex-direction: column;
    text-align: center;
  }
  .horse-image {
    max-width: 150px;
  }
  .authenticity-content.expanded .horse-image {
    max-width: 120px;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
