.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  // max-width: 90vw;
  height: 90vh;
  background-color: white;
  overflow: hidden;
  border-radius: 8px;
}

.modal-media-container {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.modal-media {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  background-color: black;
  padding: 5px 10px;
  border-radius: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90vw;
  max-height: 95vh;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-media {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  background-color: black;
  padding: 5px 10px;
  border-radius: 5px;
}
.video-media {
  width: 100%;
  height: auto;
  object-fit: contain;
}
