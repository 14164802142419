.About-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 60vh;
  width: 100%;
  .About {
    width: 100%;
    height: inherit;
    background-color: #e2e0e0d5;
    display: flex;
  }

  .About-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 80%;
    margin: auto;

    h1 {
      font-size: 1.6rem;
      margin: 0;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
      margin: 0;
    }

    .button-group {
      display: flex;
      gap: 30px;

      .button-explore {
        padding: 10px 20px;
        font-size: 1rem;
        cursor: pointer;
        border: 2px #000 unset;
        color: #000;
        background-color: white;

        &:hover {
          background-color: #96969648;
        }
      }

      .span-about {
        font-size: 1rem;
        color: #000;
        cursor: pointer;
        text-decoration: underline;
        text-underline-offset: 6px;
        border: 10px;
        line-height: 37px;
      }
    }
  }

  .About-image {
    height: inherit;

    img {
      width: 100%;
      height: 100%;
    }
  }

  /* Responsive Design */
  @media (max-width: 1024px) {
    height: auto;

    .About {
      height: 85vh;
    }
    .About-content {
      width: 90%;
      height: inherit;
      display: flex;
      justify-content: center;

      h1 {
        font-size: 1.8rem;
      }

      p {
        font-size: 0.95rem;
      }
    }

    .About-image {
      height: 85vh;

      img {
        height: inherit;
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    .About {
      flex-direction: column;
      height: 90vh;
    }

    .About-content {
      width: 95%;
      h1 {
        font-size: 1.6rem;
      }

      p {
        font-size: 0.9rem;
      }
    }

    .About-image {
      height: 50vh;

      img {
        height: inherit;
      }
    }
  }

  @media (max-width: 480px) {
    .About-content {
      h1 {
        font-size: 1.4rem;
      }

      p {
        font-size: 0.85rem;
      }
    }

    .About-image {
      height: 50vh;

      img {
        height: inherit;
      }
    }
  }
}
