main {
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.buttonButtomm {
  text-align: center;
}
@media (max-width: 800px) {
  .buttonButtomm {
    margin-top: -80%;
    position: absolute;
    text-align: center;
    width: 100%;
  }
}
.box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(23%, 1fr));
  gap: 1.5%;
  padding: 16px;
  width: 90%;
  margin: 1% auto 5%;

  /* Responsive Design */
  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    gap: 2.5%;
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    gap: 1.5%;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fit, minmax(90%, 1fr));
    padding: 8px;
    gap: 2.5%;
  }
}

@media (max-width: 600px) {
  .buttonButtomm {
    margin-top: -700px;
  }
}

@media (max-width: 480px) {
  .buttonButtomm {
    margin-top: -550px;
  }
}
