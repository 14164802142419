.artist-container {
  min-height: calc(98vh - 5.4rem);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: clamp(1rem, 2vw, 2rem);
}

.artist-header {
  overflow-x: hidden;
  border-radius: clamp(0.5rem, 1vw, 1rem);
  margin-top: clamp(1rem, 2vw, 2.4rem);
  width: clamp(70%, 80%, 90%);
  box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: clamp(1rem, 2vw, 2rem);
}

.artist-header-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: clamp(200px, 20vw, 300px);
  z-index: 1;
}

.artist-header-content {
  display: flex;
  width: 100%;
  justify-content: center;
}

.artist-header-name {
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  margin-top: clamp(0.5rem, 1vw, 1rem);
  color: #363636;
}

.artist-contact-info {
  color: #838383;
  margin-top: clamp(0.2rem, 0.5vw, 0.5rem);
}

.artist-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: clamp(20%, 30%, 40%);
  margin-bottom: clamp(0.5rem, 1vw, 1rem);
  text-align: center;
}

.artist-tegs {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  display: flex;
  width: clamp(20%, 30%, 40%);
  align-content: flex-start;
  margin-bottom: clamp(0.5rem, 1vw, 1rem);
  flex-wrap: wrap;
  padding: 0 clamp(0.5rem, 1vw, 1rem);
  margin-top: clamp(0.5rem, 1vw, 1rem);
}

.artist-extra-field {
  display: flex;
  margin-top: clamp(0.5rem, 1vw, 1rem);
  padding: 0 clamp(1rem, 2vw, 2rem);
  width: clamp(30%, 40%, 50%);
  margin-bottom: clamp(0.5rem, 1vw, 1rem);
}

.biography {
  margin-top: clamp(1rem, 2vw, 3rem);
  box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: clamp(0.5rem, 1vw, 1rem);
  padding: 0 clamp(1rem, 2vw, 2rem);
  display: flex;
  flex-direction: column;
}

.bio-title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  font-weight: 500;
}

.bio-field {
  margin-top: clamp(0.5rem, 1vw, 1rem);
  display: flex;
  width: 100%;
  border-bottom: 1px solid #000;
}

.bio-info {
  display: flex;
  margin: clamp(0.5rem, 1vw, 1rem);
}

.artist-collection {
  border-radius: clamp(0.5rem, 1vw, 1rem);
  margin-top: clamp(1rem, 2vw, 3rem);
  width: 100%;
  height: clamp(300px, 40vw, 500px);
  box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
}

.artist-head-point {
  width: 100%;
}

.artist-header-avatar {
  margin-top: clamp(-3rem, -5vw, -5rem);
  width: clamp(100px, 10vw, 150px);
  height: clamp(100px, 10vw, 150px);
  background-color: #ffffff;
  border-radius: 50%;
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-inner {
  width: 90%;
  height: 90%;
  background-color: #363636;
  border-radius: 50%;
}

.artist-collection-info {
  display: flex;
  justify-content: center;
}

.subscriptions {
  display: flex;
  margin-top: clamp(0.5rem, 1vw, 1rem);
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: clamp(0.5rem, 1vw, 0.5rem);
}

.follower-count {
  color: rgb(255, 153, 0);
}

.follow-btn {
  font-size: clamp(0.8rem, 1vw, 1rem);
  box-shadow: 3px 2px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: clamp(0.25rem, 0.5vw, 0.5rem);
  padding: clamp(0.3rem, 0.5vw, 0.4rem) clamp(1rem, 2vw, 2rem);
}

.space_object {
  width: clamp(0.5rem, 1vw, 1rem);
}

.artist-teg {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 3px 2px 10px 0px rgba(0, 0, 0, 0.25);
  height: clamp(1rem, 1.5vw, 1.5rem);
  border-radius: clamp(0.5rem, 1vw, 1rem);
  padding: 0 clamp(0.5rem, 1vw, 1rem);
  margin: clamp(0.25rem, 0.5vw, 0.5rem);
}

.tegs-title {
  width: 100%;
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  color: #838383;
  display: flex;
  justify-content: center;
  margin-bottom: clamp(0.5rem, 1vw, 0.5rem);
}

.artist-work-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  justify-items: center;
  flex-wrap: wrap;
  gap: clamp(0.5rem, 1vw, 1rem);
}

.list-item {
  display: flex;
  flex: 1 1 calc(25% - 1rem);
  margin: clamp(0.5rem, 1vw, 0.5rem);
}

.empty-card {
  width: clamp(200px, 20vw, 320px);
  height: clamp(220px, 22vw, 350px);
}

@media (min-width: 4300px) {
  .artist-container {
    margin-top: 12rem;
  }
}

@media screen and (max-width: 1440px) {
  .artist-work-list {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

@media screen and (max-width: 1024px) {
  .artist-work-list {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .artist-container {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 768px) {
  .artist-extra-field {
    display: none;
  }
  .tegs-title {
    font-size: 0.8rem;
  }
  .artist-header-name {
    font-size: 0.8rem;
  }
  .artist-collection-info {
    font-size: 0.8rem;
  }
  .artist-header-content {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .artist-header-container {
    width: 90%;
    margin-bottom: 0;
  }
  .artist-tegs {
    border-top: 1px solid #838383;
    padding-top: 0.5rem;
    width: 90%;
    border-left: none;
    border-right: none;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 600px) {
  .artist-header {
    width: 92%;
  }
}
@media screen and (max-width: 568px) {
  .artist-work-list {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
