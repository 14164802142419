.info-popup {
  z-index: 102;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 600px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.info-popup-content {
  padding: 20px;
}

.info-popup-close {
  float: right;
  cursor: pointer;
}
