.filters-container {
  width: 100%;

  .filter-button {
    text-transform: capitalize;
  }
}
.art-card-container {
  width: 80%;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: grid;
  flex-direction: wrap;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: clamp(0.5rem, 1vw, 1rem);
  min-height: 768px;
}
.marketCover {
  width: 100%;
}
.mkt-categories-box {
  margin: 0;
  padding: 0;
  flex-wrap: nowrap;
  width: 100%;
  background-color: #1d1d1d;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categories-section {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.search-field {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selector {
  display: flex;
  align-items: center;
}

.drop-tag-menu {
  width: 200px;
  padding: 0.5rem;
  margin: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #1d1d1d;

  color: #ffffff;
  font-size: 16px;
  text-transform: capitalize;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: right center;
  background-repeat: no-repeat;
}

.drop-menu::selection {
  border: 1px solid #ccc;
  background-color: #ffcc00;
  color: #7e1515;
}

.empty-card {
  width: 320px;
  height: 350px;
}

@media screen and (max-width: 1618px) {
  .art-card-container {
    width: 90%;
  }
}

@media screen and (max-width: 1440px) {
  .art-card-container {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

.filter-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
  gap: 1%;
}

@media screen and (max-width: 1024px) {
  .filter-content {
    grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
  }
  .art-card-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .flex_filter {
    flex-direction: column;
  }
  .clear-settings {
    margin-top: 10px;
  }
  .bar-text {
    font-size: 0.8rem;
  }
  .drop-tag-menu {
    padding: 0.2rem;
    margin: 0.4rem;
    width: 100px;
    font-size: 0.7rem;
  }
  .MarketPage {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .art-card-container {
    margin: 1rem 0;
    margin-bottom: 2rem;
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media screen and (max-width: 710px) {
  .art-card-container {
    width: 95%;
    margin: 0 auto 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 568px) {
  .selector p {
    display: none;
  }
  .categories-section {
    width: 80%;
  }
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  button {
    background-color: rgb(255, 94, 0);
    color: white;
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s;

    &:hover {
      background-color: rgb(237, 134, 75);
    }

    &:disabled {
      background-color: #ccc;
      color: #666;
      cursor: not-allowed;
    }
  }

  span {
    font-size: 18px;
    margin: 0 10px;
    color: #333;
  }
}

.MarketPage {
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    .page-button {
      background-color: #f1f1f1;
      border: 1px solid #ccc;
      padding: 8px 15px;
      margin: 0 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #ddd;
      }

      &.active {
        background-color: rgb(255, 94, 0);
        color: white;
        border-color: rgb(255, 94, 0);
      }

      &:disabled {
        background-color: #e0e0e0;
        cursor: not-allowed;
      }
    }

    .prev-button,
    .next-button {
      background-color: #f1f1f1;
      border: 1px solid #ccc;
      padding: 8px 15px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin: 0 10px;

      &:hover {
        background-color: #ddd;
      }

      &:disabled {
        background-color: #e0e0e0;
        cursor: not-allowed;
      }
      &.active {
        background-color: rgb(255, 94, 0);
        color: white;
        border-color: rgb(255, 94, 0);
      }
    }
  }

  .mkt-categories-box {
    margin-bottom: 20px;
  }

  .drop-tag-menu {
    font-size: 16px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
}
.dots {
  font-size: 18px;
  margin: 0 5px;
  color: #007bff;
}

.filter-container {
  font-family: Arial, sans-serif;
  padding: 10px 0;
  width: 90%;
  margin: auto;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.clear-settings {
  color: orange;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.column {
  flex: 1;
  margin: 15px 2px;
}

.column h4,
.sub-group-container h3 {
  font-size: 14px;
  margin-bottom: 5px;
  color: #b0b0b0;
}
.sub-group-container {
  margin: 15px 2px;
}

.column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.column ul li {
  font-size: 14px;
  margin-bottom: 5px;
}

.filter-button {
  background: none;
  border: none;
  font-size: 14px;
  color: #333;
  padding: 5px 10px 5px 2px;
  text-align: left;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: bold;
}

.filter-button:hover {
  background-color: #f0f0f0;
}

.filter-button.active {
  background-color: #f56c42;
  color: white;
}

.search-container {
  border-bottom: 1px solid #000000;
  padding: 5px 0;
  width: 90%;
  margin: auto;
  display: flex;
}

.search-input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  color: #000;
  padding-left: 0.5%;
}

.search-input::placeholder {
  color: #181818;
}

.slider-container {
  width: 100%;
  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 768px) {
  option {
    width: 100%;
  }
  .filter-content {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .no-items {
    margin-top: 5%;
  }
  .filter-button {
    font-size: 16px;
  }
  .sort-label {
    font-size: 18px;
  }
}

.column.split-layout .row {
  margin-bottom: 20px;
}

.column.split-layout .row h5 {
  font-size: 16px;
  margin-bottom: 10px;
}

.column.split-layout .row ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.column.split-layout .row ul li {
  margin: 0;
}

.column.split-layout .filter-button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: all 0.3s ease;
}

.column.split-layout .filter-button.active {
  background-color: #e0e0e0;
  border-color: #666;
}

.column.split-layout .filter-button:hover {
  background-color: #e0e0e0;
}

.medium-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.medium-group {
  width: 45%;
}

.medium-options {
  display: flex;
  flex-direction: column;
}

.medium-option {
  margin: 10px 0;
}
.dropdown {
  margin: 10px;
}

.dropdown-select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.market-type-header {
  font-size: 13px;
  font-weight: bold;
  width: 90%;
}
.dropdown-select option,
.dropdown-select {
  text-transform: capitalize;
}
.flex_filter {
  display: flex;
  margin: 2% auto;
}
.no-more-data,
.loading-spinner {
  margin: 10px auto;
  display: flex;
  justify-content: center;
}

.market-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
  flex-wrap: wrap;
}

.market-page-button {
  padding: 8px 16px;
  font-size: 16px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  min-width: 40px;
  text-align: center;
  border-radius: 4px; 

  &:hover:not(:disabled) {
    background-color: #f0f0f0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.active {
    background-color: orange;
    color: #fff;
    border-color: orange;
    font-weight: bold;
  }
}

.market-page-numbers {
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: nowrap;
}

.ellipsis {
  padding: 8px;
  font-size: 16px;
  color: #666;
}

.market-page-button--prev,
.market-page-button--next {
  min-width: 80px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.no-pages {
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .market-pagination {
    gap: 5px;

    .market-page-button {
      padding: 6px 12px;
      font-size: 14px;
      min-width: 35px;
    }

    .market-page-numbers .ellipsis {
      padding: 6px;
      font-size: 14px;
    }

    .market-page-button--prev,
    .market-page-button--next {
      min-width: 70px;
    }
  }
}

@media (max-width: 480px) {
  .market-pagination {
    flex-direction: row;
    gap: 3px;

    .market-page-button {
      padding: 5px 10px;
      font-size: 12px;
      min-width: 30px;
    }

    .market-page-numbers {
      flex-wrap: nowrap;
      overflow-x: auto;
      max-width: 100%;

      .ellipsis {
        padding: 5px;
        font-size: 12px;
      }
    }

    .market-page-button--prev,
    .market-page-button--next {
      min-width: 60px;
    }
  }
}